<template>
  <div class="container">
    <div class="row">


      <div class="col-md-6">
        <h3 class="text-center mb-3">Star Map</h3>

        <div class="svgElement">
          <inline-svg id="ratingIcon" :src="require('../assets/files/star_1.svg')" @loaded="onSvgLoaded"
            class="RatingIconw" ref="inlineSvgRef" />
          <div class="secondaryElement">

            <div class="mapElement">
              <div id="celestial-map"></div>
            </div>

          </div>

        </div>


      </div>


      <!-- <div id="celestial-map"></div> -->


    </div>
  </div>
</template>

<script>
  import InlineSvg from "vue-inline-svg";

  export default {
    components: {
      InlineSvg,
    },
    data() {
      return {
        starData : false
      };
    },
    mounted() {
      // this.generateStarMap();
      this.customFunction();


      // this.getDivHidden('celestial-form')
    },
    methods: {
      customFunction() {

        // let el = document.getElementById('circle11');
        // el.style.display = 'none'
        // // Get the celestial-map and customId elements
        // const celestialMap = document.getElementById('celestial-map');
        // const customId = document.getElementById('customId');

        // // // Clone the celestial-map element, including its children
        // const clonedCelestialMap = celestialMap.cloneNode(true);

        // // // Append the cloned celestial-map to the customId element
        // // customId.appendChild(clonedCelestialMap);

        // // Get the canvas element inside the cloned celestial-map
        // const canvasClone = clonedCelestialMap.querySelector('canvas');

        // // Convert the canvas content to a data URL
        // const imageDataURL = canvasClone.toDataURL();
        // // Wait for any asynchronous operations to complete
        // setTimeout(() => {
        //     const imageDataURL = canvasClone.toDataURL();
        //     const imageElement = new Image();
        //     imageElement.src = imageDataURL;
        //     document.getElementById('image-container').appendChild(imageElement);
        //   }, 100); // Adjust the timeout duration as needed

        var config = {
          zoomlevel : 1,
          disableAnimations: false,
          form: false,
          formFields: {
            download: true
          },
          datapath: "/data/",
          stars: {
              show: this.starData,    // Show stars
              limit: 6,      // Show only stars brighter than limit magnitude
              colors: false,  // Show stars in spectral colors, if not use default color
              style: { fill: "red", opacity: 1 }, // Default style for stars
              designation: true, // Show star names (Bayer, Flamsteed, Variable star, Gliese or designation, 
                                // i.e. whichever of the previous applies first); may vary with culture setting
              designationType: "desig",  // Which kind of name is displayed as designation (fieldname in starnames.json)
              designationStyle: { fill: "#ddddbb", font: "11px 'Palatino Linotype', Georgia, Times, 'Times Roman', serif", align: "left", baseline: "top" },
              designationLimit: 2.5,  // Show only names for stars brighter than nameLimit
              propername: false,   // Show proper name (if present)
              propernameType: "name", // Languge for proper name, default IAU name; may vary with culture setting 
                                      // (see list below of languages codes available for stars)
              propernameStyle: { fill: "#ddddbb", font: "13px 'Palatino Linotype', Georgia, Times, 'Times Roman', serif", align: "right", baseline: "bottom" },
              propernameLimit: 1.5,  // Show proper names for stars brighter than propernameLimit
              size: 7,       // Maximum size (radius) of star circle in pixels
              exponent: -0.28, // Scale exponent for star size, larger = more linear
              data: 'stars.6.json' // Data source for stellar data, 
                                  // number indicates limit magnitude
            },
        };

      

        // Display map with the configuration above or any subset thereof
        Celestial.display(config);



      },

      onSvgLoaded() {

        let svgComponent = document.getElementById('ratingIcon');
        svgComponent.style.width = '60%';
        svgComponent.style.margin = '60 0 60 0';
        svgComponent.style.border = '3px solid black';

        // this.generateStreetMap()
      },

      applyBootstrapStyles() {
        const location = this.getDiv('loc');
        const general = this.getDiv('general');
        const stars = this.getDiv('stars');


        this.addFormControlClassToChildren(location.id);
        this.addFormControlClassToChildren(stars.id);
      },
      addFormControlClassToChildren(parentId) {
        const parentElement = document.getElementById(parentId);

        if (parentElement) {
          const children = parentElement.children;

          for (let i = 0; i < children.length; i++) {
            const child = children[i];
            child.classList.add('form-control');
          }
        } else {
          console.error('Parent element not found');
        }
      },
      generateStarMap() {
        var config = {
          disableAnimations: false,
          form: true,
          formFields: {
            download: true
          },
          datapath: "/data/",
          stars: {
            propername: true
          },
          // orientationfixed: true, 
          advanced: false,
        };
        Celestial.display(config);

        console.log(Celestial);

      },

      getDiv(id) {
        const el = document.getElementById(id);
        return el;
      },
      getDivHidden(id) {
        this.getDiv(id).style.display = 'none';
      },
      getDivShow(id) {
        this.getDiv(id).style.display = 'none';
      }

    },
  };
</script>

<style scoped>
  .ImgRemoveBtn {
    display: none;
    cursor: pointer;
    color: red;

  }

  .svgElement {
    position: relative;
    z-index: -1;
  }

  .secondaryElement {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
  }

  .mapElement {
    position: absolute;
    content: '';
    left: 50%;
    top: 120px;
    transform: translateX(-50%);
    z-index: 99;

    width: 45%;
    height: 45.4%;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #celestial-map {
    z-index: 999 !important;
  }

  #celestial-form {
    position: absolute !important;
    content: '';
    right: 0;
    top: 0;
    z-index: 999;

  }
</style>