<template>

    <main class="py-5">
        <section class="configurator1">
            <div class="container">
                <h1 class="poster_section_title mb-4">Template <strong>Name</strong></h1>

                <!-- main configurator -->
                <div class="row py-4">



                    <div class="col-md-7 d-flex gap-5 mb-4">
                        <div class="d-flex flex-column gap-2 site_poster_content">
                            <img src="../assets/files/custom-svg-3.svg" alt="" class="thumbnails" style="border: 2px solid black;">
                            <img src="../assets/files/custom-svg-3.svg" alt="" class="thumbnails" style="border: 2px solid black;">
                            <img src="../assets/files/custom-svg-3.svg" alt="" class="thumbnails" style="border: 2px solid black;">
                            <img src="../assets/files/custom-svg-3.svg" alt="" class="thumbnails" style="border: 2px solid black;">
                        </div>

                        <!-- main output -->
                        <div class="main_image_content">
                            <!-- <img src="../assets/files/custom-svg-3.svg" alt="" class="output_image Border" id="output"> -->
                            <RatingIcon></RatingIcon>
                        </div>
                    </div>

                    <!-- input content -->
                    <div class="col-md-5" style="display: none;">
                        <div class="py-4 px-4 bg-light shadow-sm input_content">
                            <div class="d-flex align-items-center justify-content-between">
                                <div><img src="../assets/images/confi-image/bulve.png" class="bulve" alt=""></div>
                                <div class="save_text"><a href="">Save</a></div>
                            </div>


                            <!-- tab button -->
                            <div class="tab_content">
                                <ul class="nav nav-pills" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active  shadow-lg confi_button" id="design-tab"
                                            data-bs-toggle="tab" data-bs-target="#design" type="button" role="tab"
                                            aria-controls="design" aria-selected="true">Design</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link shadow-lg confi_button" id="moment-tab"
                                            data-bs-toggle="tab" data-bs-target="#moment" type="button" role="tab"
                                            aria-controls="moment" aria-selected="false">Moment</button>
                                    </li>
                                    <li class="nav-item " role="presentation">
                                        <button class="nav-link shadow-lg confi_button" id="customize-tab"
                                            data-bs-toggle="tab" data-bs-target="#customize" type="button" role="tab"
                                            aria-controls="customize" aria-selected="false">Customize</button>
                                    </li>
                                    <li class="nav-item " role="presentation">
                                        <button class="nav-link shadow-lg confi_button" id="size-tab"
                                            data-bs-toggle="tab" data-bs-target="#size" type="button" role="tab"
                                            aria-controls="size" aria-selected="false">Size</button>
                                    </li>
                                </ul>



                                <div class="tab-content" id="myTabContent">


                                    <!-- deign tab content -->
                                    <div class="tab-pane fade show active"
                                        style="background-color: transparent !important;" id="design" role="tabpanel"
                                        aria-labelledby="design-tab">
                                        <div class="mt-4 px-2">
                                            <p class="select_your_design">Select your favorite design:</p>

                                            <!-- accordion -->


                                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                                <div class="accordion-item" id="accordion_item">
                                                    <h2 class="accordion-header" id="flush-headingOne">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#birthday"
                                                            aria-expanded="false" aria-controls="birthday"
                                                            id="confi_accordion_button">
                                                            <img src="../assets/images/confi-image/birthday.png"
                                                                class="accordion_confi_image" alt=""> Birthday
                                                        </button>
                                                    </h2>
                                                    <div id="birthday" class="accordion-collapse collapse"
                                                        aria-labelledby="flush-headingOne"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body overflow-hidden">
                                                            <div class="d-flex align-items-center  row">
                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-item" id="accordion_item">
                                                    <h2 class="accordion-header" id="flush-headingTwo">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#loved"
                                                            aria-expanded="false" aria-controls="loved">
                                                            <img src="../assets/images/confi-image/heart.png"
                                                                class="accordion_confi_image" alt=""> For Loved One
                                                        </button>
                                                    </h2>
                                                    <div id="loved" class="accordion-collapse collapse"
                                                        aria-labelledby="loved" data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body overflow-hidden">
                                                            <div class="d-flex align-items-center  row">
                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-item" id="accordion_item">
                                                    <h2 class="accordion-header" id="flush-headingThree">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#parents"
                                                            aria-expanded="false" aria-controls="parents">
                                                            <img src="../assets/images/confi-image/user-confi.png"
                                                                class="accordion_confi_image" alt=""> For Parents
                                                        </button>
                                                    </h2>
                                                    <div id="parents" class="accordion-collapse collapse"
                                                        aria-labelledby="parents"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body overflow-hidden">
                                                            <div class="d-flex align-items-center  row">
                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-item" id="accordion_item">
                                                    <h2 class="accordion-header" id="flush-headingThree">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#wedding"
                                                            aria-expanded="false" aria-controls="wedding">
                                                            <img src="../assets/images/confi-image/right-confi.png"
                                                                class="accordion_confi_image" alt=""> Anniversary &
                                                            Wedding
                                                        </button>
                                                    </h2>
                                                    <div id="wedding" class="accordion-collapse collapse"
                                                        aria-labelledby="wedding"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body overflow-hidden">
                                                            <div class="d-flex align-items-center  row">
                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="accordion-item" id="accordion_item">
                                                    <h2 class="accordion-header" id="flush-headingThree">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#family"
                                                            aria-expanded="false" aria-controls="family">
                                                            <img src="../assets/images/confi-image/users-confi.png"
                                                                class="accordion_confi_image" alt=""> Family
                                                        </button>
                                                    </h2>
                                                    <div id="family" class="accordion-collapse collapse"
                                                        aria-labelledby="family"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body overflow-hidden">
                                                            <div class="d-flex align-items-center  row">
                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="">
                                                                        <img src="../assets/images/confi-poster-shape.png"
                                                                            class="birthday_template" alt="">
                                                                        <p class="birthday_template_text">Template Name
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <a href="" style="text-decoration: none;">
                                                                        <div class="">
                                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                                class="birthday_template" alt="">
                                                                            <p class="birthday_template_text">Template
                                                                                Name</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <!-- end  -->






                                    <!-- moment tab content -->
                                    <div class="tab-pane fade" id="moment" role="tabpanel" aria-labelledby="moment-tab">
                                        <div class="mt-4 px-2">
                                            <p class="select_your_design">Select your favorite design:</p>

                                            <!-- accordion -->


                                            <div class="accordion accordion-flush" id="accordionFlushExample">



                                                <div class="accordion-item" id="accordion_item">
                                                    <h2 class="accordion-header" id="flush-headingOne">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#birthday"
                                                            aria-expanded="false" aria-controls="birthday"
                                                            id="confi_accordion_button">
                                                            Upload Your Photo
                                                        </button>
                                                    </h2>
                                                    <div id="birthday" class="accordion-collapse collapse"
                                                        aria-labelledby="flush-headingOne"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body overflow-hidden">
                                                            <div class="d-flex align-items-center  row">

                                                                <div class="col-md-4">
                                                                    <!-- upload photo 1 -->

                                                                    <div class="col">
                                                                        <div class="file-upload">
                                                                            <label for="file-input" class="file-label">
                                                                                <span class="icon">
                                                                                    <img src="../assets/images/add_photo.png"
                                                                                        class="add_photo_image" alt="">
                                                                                </span>
                                                                            </label>
                                                                            <input type="file" id="file-input"
                                                                                class="file-input" />

                                                                        </div>
                                                                        <p class="add_image_title">Image One</p>
                                                                    </div>








                                                                </div>

                                                                <div class="col-md-4">
                                                                    <!-- upload photo 2 -->
                                                                    <div class="col">
                                                                        <div class="file-upload">
                                                                            <label for="file-input" class="file-label">
                                                                                <span class="icon">
                                                                                    <img src="../assets/images/add_photo.png"
                                                                                        class="add_photo_image" alt="">
                                                                                </span>
                                                                            </label>
                                                                            <input type="file" id="file-input"
                                                                                name="image_two" class="file-input" />

                                                                        </div>
                                                                        <p class="add_image_title">Image Two</p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-4">

                                                                </div>




                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-item" id="accordion_item">
                                                    <h2 class="accordion-header" id="flush-headingTwo">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#loved"
                                                            aria-expanded="false" aria-controls="loved">
                                                            Moment 1
                                                        </button>
                                                    </h2>
                                                    <div id="loved" class="accordion-collapse collapse show"
                                                        aria-labelledby="loved" data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body overflow-hidden">
                                                            <div class="d-flex align-items-center  row">
                                                                <!-- body -->
                                                                <div class="col-md-12">
                                                                    <div class="custom-select2">
                                                                        <select
                                                                            class="location_select location_select form-select"
                                                                            data-live-search="true">
                                                                            <option class="select_title" selected
                                                                                disabled>Location</option>
                                                                            <option value="option2">Lorem Ipsum</option>
                                                                            <option value="">Lorem Ipsum</option>
                                                                            <option value="option3">Lorem Ipsum</option>
                                                                            <option value="">Lorem Ipsum</option>
                                                                            <option value="">Lorem Ipsum</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12">
                                                                    <div class="custom-select3 mt-4" id="">
                                                                        <input type="text" id="datepicker"
                                                                            class="form-control datepicker moment_date_picker1"
                                                                            placeholder="Date">
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12 mt-4">
                                                                    <div
                                                                        class="d-flex align-items-center justify-content-between">
                                                                        <div>
                                                                            <p class="use_default_time_text">Use Default
                                                                                Time</p>
                                                                        </div>
                                                                        <div>
                                                                            <div class="custom-switch">
                                                                                <input type="checkbox"
                                                                                    class="custom-control-input"
                                                                                    id="customSwitch">
                                                                                <label class="custom-control-label"
                                                                                    for="customSwitch"></label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12">
                                                                    <div class="custom-select_time mt-2 position-relative"
                                                                        id="">
                                                                        <div><img src="../assets/images/time.png" alt=""
                                                                                class="time_icon"></div>
                                                                        <input type="text" cl
                                                                            class="form-control  current_time" disabled
                                                                            placeholder="Time">
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-item" id="accordion_item">
                                                    <h2 class="accordion-header" id="flush-headingThree">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#parents"
                                                            aria-expanded="false" aria-controls="parents">
                                                            Moment 2
                                                        </button>
                                                    </h2>
                                                    <div id="parents" class="accordion-collapse collapse"
                                                        aria-labelledby="parents"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body overflow-hidden">
                                                            <div class="d-flex align-items-center  row">
                                                                <!-- body -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>





                                            </div>


                                        </div>
                                    </div>

                                    <!-- end -->




                                    <!-- customize tab content -->
                                    <div class="tab-pane fade" id="customize" role="tabpanel"
                                        aria-labelledby="customize-tab">
                                        <div class="mt-4 px-2">
                                            <p class="select_your_design">Select your favorite design:</p>

                                            <!-- accordion -->


                                            <div class="accordion accordion-flush" id="accordionFlushExample">



                                                <div class="accordion-item" id="accordion_item">
                                                    <h2 class="accordion-header" id="flush-headingOne">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#birthday"
                                                            aria-expanded="false" aria-controls="birthday"
                                                            id="confi_accordion_button">
                                                            Text
                                                        </button>
                                                    </h2>
                                                    <div id="birthday" class="accordion-collapse collapse"
                                                        aria-labelledby="flush-headingOne"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body overflow-hidden">
                                                            <div class="d-flex align-items-center  row">
                                                                <div class="col-md-12">
                                                                    <input type="text" name="main_text" id="main_text"
                                                                        placeholder="Main Text...."
                                                                        class="form-control">
                                                                </div>
                                                                <div class="col-md-12 mt-4">
                                                                    <textarea placeholder="Enter your second text..."
                                                                        class="form-control"
                                                                        id="second_text"></textarea>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-item" id="accordion_item">
                                                    <h2 class="accordion-header" id="flush-headingTwo">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#loved"
                                                            aria-expanded="false" aria-controls="loved">
                                                            Font Style
                                                        </button>
                                                    </h2>
                                                    <div id="loved" class="accordion-collapse collapse show"
                                                        aria-labelledby="loved" data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body overflow-hidden">
                                                            <div class="d-flex align-items-center">
                                                                <!-- body -->
                                                                <div class="row">
                                                                    <div class="col-md-4">
                                                                        <div class="dropdown">
                                                                            <input type="button" value="Main Text"
                                                                                class="frame_button shadow active_frame_button">
                                                                            <!--font style  -->
                                                                            <ul class="list-group font_style_dropdown mt-2"
                                                                                aria-labelledby="dropdownMenuButton1">
                                                                                <li class="dropdown-item"><a
                                                                                        href="">Font 1</a></li>
                                                                                <li class="dropdown-item"><a
                                                                                        href="">Fira COde</a></li>
                                                                                <li class="dropdown-item"><a
                                                                                        href="">Bardana</a></li>
                                                                                <li class="dropdown-item"><a
                                                                                        href="">Latin</a></li>
                                                                                <li class="dropdown-item"><a
                                                                                        href="">Jost</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-4">
                                                                        <input type="button" href="#" value="2nd Text"
                                                                            class="frame_button shadow">
                                                                        <ul class="list-group font_style_dropdown mt-2"
                                                                            aria-labelledby="dropdownMenuButton1">
                                                                            <li class="dropdown-item"><a href="">Font
                                                                                    1</a></li>
                                                                            <li class="dropdown-item"><a href="">Fira
                                                                                    COde</a></li>
                                                                            <li class="dropdown-item"><a
                                                                                    href="">Bardana</a></li>
                                                                            <li class="dropdown-item"><a
                                                                                    href="">Latin</a></li>
                                                                            <li class="dropdown-item"><a
                                                                                    href="">Jost</a></li>
                                                                        </ul>
                                                                    </div>


                                                                    <div class="col-md-4">
                                                                        <input type="button" href="#" value="3rd Text"
                                                                            class="frame_button shadow">
                                                                        <ul class="list-group font_style_dropdown mt-2"
                                                                            aria-labelledby="dropdownMenuButton1">
                                                                            <li class="dropdown-item"><a href="">Font
                                                                                    1</a></li>
                                                                            <li class="dropdown-item"><a href="">Fira
                                                                                    COde</a></li>
                                                                            <li class="dropdown-item"><a
                                                                                    href="">Bardana</a></li>
                                                                            <li class="dropdown-item"><a
                                                                                    href="">Latin</a></li>
                                                                            <li class="dropdown-item"><a
                                                                                    href="">Jost</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="accordion-item" id="accordion_item">
                                                    <h2 class="accordion-header" id="flush-headingThree">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#parents"
                                                            aria-expanded="false" aria-controls="parents">
                                                            Starmap
                                                        </button>
                                                    </h2>
                                                    <div id="parents" class="accordion-collapse collapse"
                                                        aria-labelledby="parents"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body overflow-hidden">
                                                            <div class="d-flex align-items-center  row">
                                                                <!-- body -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="accordion-item" id="accordion_item">
                                                    <h2 class="accordion-header" id="flush-headingThree">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#parents"
                                                            aria-expanded="false" aria-controls="parents">
                                                            Streetmap
                                                        </button>
                                                    </h2>
                                                    <div id="parents" class="accordion-collapse collapse"
                                                        aria-labelledby="parents"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body overflow-hidden">
                                                            <div class="d-flex align-items-center  row">
                                                                <!-- body -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="accordion-item" id="accordion_item">
                                                    <h2 class="accordion-header" id="flush-headingThree">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#parents"
                                                            aria-expanded="false" aria-controls="parents">
                                                            Color
                                                        </button>
                                                    </h2>
                                                    <div id="parents" class="accordion-collapse collapse"
                                                        aria-labelledby="parents"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body overflow-hidden">
                                                            <div class="d-flex align-items-center  row">
                                                                <!-- body -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>





                                            </div>


                                        </div>
                                    </div>


                                    <!-- end  -->



                                    <!-- size tab content -->

                                    <div class="tab-pane fade" id="size" role="tabpanel" aria-labelledby="size-tab">
                                        <div class="mt-4 px-2">
                                            <p class="select_your_design">Select your favorite design:</p>


                                            <div class="mt-4">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="d-flex align-items-center gap-2">
                                                        <div><input type="radio" name="inch&price"
                                                                class="form-check-input" id="inch&price"> <span
                                                                class="inch_text">12 * 16 inch</span></div>
                                                    </div>
                                                    <div>
                                                        <div> <span class="price"><del>৳1,595</del></span> <span
                                                                class="selling_price">৳1,595</span></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-4">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="d-flex align-items-center gap-2">
                                                        <div><input type="radio" name="inch&price"
                                                                class="form-check-input" id="inch&price"> <span
                                                                class="inch_text">18 * 24 inch</span></div>
                                                    </div>
                                                    <div>
                                                        <div> <span class="price"><del>৳1,595</del></span> <span
                                                                class="selling_price">৳1,595</span></div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="mt-4">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="d-flex align-items-center gap-2">
                                                        <div><input type="radio" name="inch&price"
                                                                class="form-check-input" id="inch&price"> <span
                                                                class="inch_text">24 * 32 inch</span></div>
                                                    </div>
                                                    <div>
                                                        <div> <span class="price"><del>৳1,595</del></span> <span
                                                                class="selling_price">৳1,595</span></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-4">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="d-flex align-items-center gap-2">
                                                        <div>
                                                            <input type="radio" name="inch&price"
                                                                class="form-check-input custom_inch_radio"
                                                                id="inch&price">
                                                            <span class="custom">Custom</span>
                                                            <span><input type="text" name="" id=""
                                                                    class="custom_inch_value1" placeholder="in"></span>
                                                            <span>*</span>
                                                            <span><input type="text" name="" id=""
                                                                    class="custom_inch_value2" placeholder="in"></span>

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div> <span class="price"><del>৳1,595</del></span> <span
                                                                class="selling_price">৳1,595</span></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-4 custom_border_for_size">

                                            </div>


                                            <!-- chose frame -->

                                            <div class="mt-4">
                                                <p class="choose_frame_text">Choose Frame</p>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <input type="button" href="#" value="Standart"
                                                            class="frame_button shadow active_frame_button">
                                                    </div>

                                                    <div class="col-md-3">
                                                        <input type="button" href="#" value="Metal"
                                                            class="frame_button shadow">
                                                    </div>


                                                    <div class="col-md-3">
                                                        <input type="button" href="#" value="Wood"
                                                            class="frame_button shadow">
                                                    </div>



                                                </div>
                                            </div>





                                        </div>
                                    </div>

                                    <!-- end size tab content -->



                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="row price_addtocart_next_section">
                    <div class="col-md-6"></div>
                    <div class="col-md-6  text-center">
                        <div class="card shadow py-4 px-4">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="to_pay">To Pay:</div>
                                <div class="total_price">৳1,299</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mt-4">
                                <div>
                                    <a href="configurator2.html" class="next_process">Add To Cart <img
                                            src="../assets/images/cart.png" alt="" class="cart_icon"> </a>

                                </div>
                                <div>
                                    <a href="configurator2.html" class="next_button">Next</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </main>


</template>


<script>
import RatingIcon from './RatingIcon3.vue'
    export default {
        name: 'Config2',
        components: {
            RatingIcon
        },


    }
</script>

<style scoped>

.Border{
    border: 2px solid black;
}
</style>