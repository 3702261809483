<template>
    <div class="row">
      <div class="col-lg-6">
        <inline-svg id="ratingIcon" :src="require('../assets/files/custom-svg-2.svg')" class="RatingIconw"  @loaded="onSvgLoaded" ref="inlineSvgRef" />
      </div>
      <div class="col-lg-6">
        <div class="container" style="max-width: 350px; margin-top: 20%; margin-left: 0px; text-align: left;">
          <form>
            <!-- Dynamically generate form fields for each ID in the array -->
            <div v-for="(inputId, index) in arryText" :key="index" class="form-group mb-3">
              <label :for="inputId">Field {{ index + 1 }}:</label>
              <input type="text" class="form-control" :id="inputId" :placeholder="'Enter value for ' + inputId" v-model="svg[inputId]" />
            </div>

            
            <!-- Dynamically generate image input fields -->
            <div v-for="(imageId, index) in arryImage" :key="index" class="form-group mb-3">
                <label :for="imageId">Image {{ index + 1 }}:</label>
                <input type="file" class="form-control" :id="imageId" accept="image/*" @change="onFileChange(imageId, $event)" />
            </div>
         
            
            
            <!-- <button type="submit" class="btn btn-primary">Submit</button> -->
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import InlineSvg from "vue-inline-svg";
  
  export default {
    name: "RatingIcon",
    components: {
      InlineSvg,
    },
    data() {
      return {
        svg: {
          
        },
        arryText: [
          'textTag1',
          
        ],
        arryImage: [
          'imageTag1',
          'imageTag2',
          'imageTag3',
          
        ],
        
        url: null,
      };
    },
    methods: {
      onFileChange(imageId, e) {
       
        const file = e.target.files[0];
        this.url = URL.createObjectURL(file);

        let previewImage = document.getElementById(imageId);
        previewImage.setAttribute('xlink:href', this.url);

      },
      onSvgLoaded()
      {

        document.getElementById('component1').style.display = 'none';

        let svgComponent = document.getElementById('ratingIcon');
        svgComponent.style.width = '60%';
        svgComponent.style.margin = '60 0 60 0';
        svgComponent.style.border = '3px solid black';
      }
     
    },
    
    watch: {
      svg: {
        handler(newValues) {
          // Iterate over the properties of svg and update corresponding elements in the SVG
          for (const [id, value] of Object.entries(newValues)) {
            const svgElement = this.$refs.inlineSvgRef.$el.querySelector(`#${id}`);
            if (svgElement) {
              svgElement.innerHTML = value;
            }
          }
        },
        deep: true, // Enable deep watching to watch nested properties
      },
    },
  };
  </script>
  
  <style scoped>
  /* No CSS needed in this approach */
  </style>
  