<template>

  <main>
    <!-- banner section start  -->

    <section class="banner_section" id="banner_section">
      <div style="position: relative;">
        <img src="../assets/images/background.png" alt="" class="hero_sky_banner_image">
        <div class="overlay"></div>
      </div>
      <div class="container">
        <div class="row row-cols-1 row-cols-sm-1  row-cols-md-2">
          <div class="col">
            <div class="hero-content">
              <h1 class="hero_title" data-aos="fade-down"><strong class="discovered">Discovered</strong> Akash Patal
              </h1>
              <h3 class="hero_subtitle" data-aos="fade-right">Capture your treasured memories in a beautiful poster</h3>
              <p class="lead hero_text" data-aos="fade-left">Custom starmaps, streetmaps & more - Thoughtful gifts to
                celebrate life's milestones. Craft your own design or explore our diverse collection of
                ready-to-customize poster templates.</p>
              <div class="d-flex align-items-center gap-4">
                <a href="#" class=" create_poster">Create your poster</a>
                <a href="#" class=" explore_more">Explore More</a>
              </div>
            </div>
          </div>
          <div class="col">

            <div class="row  row-cols-lg-2 row-cols-md-1  row-cols-1 banner_poster_content" id="banner_poster_content">
              <!-- strep map -->
              <div class="col flex-column gap-4 d-none d-lg-flex">
                <div class="card shadow">
                  <img src="../assets/images/poster_image/strep1.png" class="card-img-top" alt="">
                  <div class="card-body">
                    <div class="d-flex align-items-center gap-3">
                      <div>
                        <img src="../assets/images/user.png" alt="" class="poster_user_image">
                      </div>
                      <div class="poster_user_name">
                        John Smith
                      </div>
                    </div>
                    <div class="star_icon mt-2 mb-2">
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                    </div>
                    <p class="poster_user_review">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                  </div>
                </div>

                <div class="card shadow">
                  <img src="../assets/images/poster_image/strep2.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <div class="d-flex align-items-center gap-3">
                      <div>
                        <img src="../assets/images/user.png" alt="" class="poster_user_image">
                      </div>
                      <div class="poster_user_name">
                        John Smith
                      </div>
                    </div>
                    <div class="star_icon mt-2 mb-2">
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                    </div>
                    <p class="poster_user_review">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                  </div>
                </div>
              </div>

              <!--starmap  -->
              <div class="col d-flex flex-column gap-4">
                <div class="card shadow">
                  <img src="../assets/images/poster_image/star1.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <div class="d-flex align-items-center gap-3">
                      <div>
                        <img src="../assets/images/user.png" alt="" class="poster_user_image">
                      </div>
                      <div class="poster_user_name">
                        John Smith
                      </div>
                    </div>
                    <div class="star_icon mt-2 mb-2">
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                    </div>
                    <p class="poster_user_review">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                  </div>
                </div>

                <div class="card shadow">
                  <img src="../assets/images/poster_image/star2.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <div class="d-flex align-items-center gap-3">
                      <div>
                        <img src="../assets/images/user.png" alt="" class="poster_user_image">
                      </div>
                      <div class="poster_user_name">
                        John Smith
                      </div>
                    </div>
                    <div class="star_icon mt-2 mb-2">
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                      <span><i class="fa fa-star" aria-hidden="true"></i></span>
                    </div>
                    <p class="poster_user_review">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section>
    <section class="coupon_banner">
      <div class="">
        <div class="row">
          <a href="">
            <div class="col-md-12">
              <img src="../assets/images/cupon_banner.png" alt="" class="coupon_banner">
            </div>
          </a>
        </div>
      </div>
    </section>
    <!-- banner section end  -->

    <!-- poster section start  -->

    <section class="poster_section">
      <div class="container">
        <h1 class="poster_section_title">New <strong class="poster">Posters</strong></h1>
        <p class="poster_section_subtitle">Choose a ready-made template or create your own poster.</p>
        <div class="categories_content d-flex justify-content-between align-items-center row">
          <div class="categories col-lg-10">
            <ul class="nav nav-tabs d-flex flex-wrap align-items-center gap-4  mb-4 mb-lg-0" id="myTab" role="tablist">
              <li>
                <button class="category_btn" type="button" data-filter="all">All</button>
              </li>
              <li>
                <button class="category_btn" type="button" data-filter=".wedding">Wedding</button>
              </li>
              <li>
                <button class="category_btn" type="button" data-filter=".birthday">Birthday</button>
              </li>
              <li>
                <button class="category_btn" type="button" data-filter=".couple">Couple</button>
              </li>

              <li>
                <button class="category_btn" data-filter=".family">Family</button>
              </li>

            </ul>
          </div>
          <div class="explore_more_button_content  col-lg-2 m-2 m-md-0">
            <a href="#" class="explore_more2">Explore More</a>
          </div>
        </div>

        <!-- tab content start here -->
        <div>
          <!-- all posters -->
          <div class="row g-2 row-cols-lg-3 row-cols-1 row-cols-md-2" id="poster_content_res">
            <!-- single poster -->
            <div class="col mb-4 mix all couple">
              <a href="#" class="single_poster_card position-relative">
                <div class="card shadow-sm position-relative overflow-hidden" id="poster_card" style="width: 19rem;">
                  <div class="parchentage_content">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="parchen shadow-sm"> -10%</div>
                      <div class="star shadow-sm"><i class="fa fa-star-o" aria-hidden="true"></i> <span>120k</span>
                      </div>
                    </div>
                  </div>

                  <div class="cwc">
                    <div class="cwc_icon_content d-flex flex-column align-items-center gap-4">
                      <div> <a href="" title="Add To Cart" class="cwc_icon"><i class="fa fa-shopping-cart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Wishlist" class="cwc_icon"><i class="fa fa-heart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Compare" class="cwc_icon"><i class="fa fa-refresh"
                            aria-hidden="true"></i></a></div>
                    </div>
                  </div>
                  <img src="../assets/images/poster_images2/poster1.png" class="poster_image" alt="...">
                  <div class="card-body py-4">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="">
                        <span class="poster_price">৳ 1,235</span>
                        <del class="poster_del_price">৳ 1,495</del>
                      </div>
                      <div>
                        <a href="" class="customize">Customize</a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!-- end single poster -->
            <!-- single poster -->
            <div class="col mb-4 mix couple">
              <a href="#" class="single_poster_card position-relative">
                <div class="card shadow-sm position-relative overflow-hidden" id="poster_card" style="width: 19rem;">
                  <div class="parchentage_content">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="parchen shadow-sm"> -10%</div>
                      <div class="star shadow-sm"><i class="fa fa-star-o" aria-hidden="true"></i> <span>120k</span>
                      </div>
                    </div>
                  </div>

                  <div class="cwc">
                    <div class="cwc_icon_content d-flex flex-column align-items-center gap-4">
                      <div> <a href="" title="Add To Cart" class="cwc_icon"><i class="fa fa-shopping-cart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Wishlist" class="cwc_icon"><i class="fa fa-heart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Compare" class="cwc_icon"><i class="fa fa-refresh"
                            aria-hidden="true"></i></a></div>
                    </div>
                  </div>
                  <img src="../assets/images/poster_images2/poster2.png" class="poster_image" alt="...">
                  <div class="card-body py-4">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="">
                        <span class="poster_price">৳ 1,235</span>
                        <del class="poster_del_price">৳ 1,495</del>
                      </div>
                      <div>
                        <a href="" class="customize">Customize</a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!-- end single poster -->
            <!-- single poster -->
            <div class="col mb-4 mix couple">
              <a href="#" class="single_poster_card position-relative">
                <div class="card shadow-sm position-relative overflow-hidden" id="poster_card" style="width: 19rem;">
                  <div class="parchentage_content">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="parchen shadow-sm"> -10%</div>
                      <div class="star shadow-sm"><i class="fa fa-star-o" aria-hidden="true"></i> <span>120k</span>
                      </div>
                    </div>
                  </div>

                  <div class="cwc">
                    <div class="cwc_icon_content d-flex flex-column align-items-center gap-4">
                      <div> <a href="" title="Add To Cart" class="cwc_icon"><i class="fa fa-shopping-cart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Wishlist" class="cwc_icon"><i class="fa fa-heart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Compare" class="cwc_icon"><i class="fa fa-refresh"
                            aria-hidden="true"></i></a></div>
                    </div>
                  </div>
                  <img src="../assets/images/poster_images2/poster3.png" class="poster_image" alt="...">
                  <div class="card-body py-4">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="">
                        <span class="poster_price">৳ 1,235</span>
                        <del class="poster_del_price">৳ 1,495</del>
                      </div>
                      <div>
                        <a href="" class="customize">Customize</a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!-- end single poster -->
            <!-- single poster -->
            <div class="col mb-4 mix wedding">
              <a href="#" class="single_poster_card position-relative">
                <div class="card shadow-sm position-relative overflow-hidden" id="poster_card" style="width: 19rem;">
                  <div class="parchentage_content">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="parchen shadow-sm"> -10%</div>
                      <div class="star shadow-sm"><i class="fa fa-star-o" aria-hidden="true"></i> <span>120k</span>
                      </div>
                    </div>
                  </div>

                  <div class="cwc">
                    <div class="cwc_icon_content d-flex flex-column align-items-center gap-4">
                      <div> <a href="" title="Add To Cart" class="cwc_icon"><i class="fa fa-shopping-cart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Wishlist" class="cwc_icon"><i class="fa fa-heart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Compare" class="cwc_icon"><i class="fa fa-refresh"
                            aria-hidden="true"></i></a></div>
                    </div>
                  </div>
                  <img src="../assets/images/poster_images2/poster4.png" class="poster_image" alt="...">
                  <div class="card-body py-4">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="">
                        <span class="poster_price">৳ 1,235</span>
                        <del class="poster_del_price">৳ 1,495</del>
                      </div>
                      <div>
                        <a href="" class="customize">Customize</a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!-- end single poster -->
            <!-- single poster -->
            <div class="col mb-4 mix wedding">
              <a href="#" class="single_poster_card position-relative">
                <div class="card shadow-sm position-relative overflow-hidden" id="poster_card" style="width: 19rem;">
                  <div class="parchentage_content">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="parchen shadow-sm"> -10%</div>
                      <div class="star shadow-sm"><i class="fa fa-star-o" aria-hidden="true"></i> <span>120k</span>
                      </div>
                    </div>
                  </div>

                  <div class="cwc">
                    <div class="cwc_icon_content d-flex flex-column align-items-center gap-4">
                      <div> <a href="" title="Add To Cart" class="cwc_icon"><i class="fa fa-shopping-cart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Wishlist" class="cwc_icon"><i class="fa fa-heart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Compare" class="cwc_icon"><i class="fa fa-refresh"
                            aria-hidden="true"></i></a></div>
                    </div>
                  </div>
                  <img src="../assets/images/poster_images2/poster5.png" class="poster_image" alt="...">
                  <div class="card-body py-4">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="">
                        <span class="poster_price">৳ 1,235</span>
                        <del class="poster_del_price">৳ 1,495</del>
                      </div>
                      <div>
                        <a href="" class="customize">Customize</a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!-- end single poster -->
            <!-- single poster -->
            <div class="col mb-4 mix birthday">
              <a href="#" class="single_poster_card position-relative">
                <div class="card shadow-sm position-relative overflow-hidden" id="poster_card" style="width: 19rem;">
                  <div class="parchentage_content">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="parchen shadow-sm"> -10%</div>
                      <div class="star shadow-sm"><i class="fa fa-star-o" aria-hidden="true"></i> <span>120k</span>
                      </div>
                    </div>
                  </div>

                  <div class="cwc">
                    <div class="cwc_icon_content d-flex flex-column align-items-center gap-4">
                      <div> <a href="" title="Add To Cart" class="cwc_icon"><i class="fa fa-shopping-cart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Wishlist" class="cwc_icon"><i class="fa fa-heart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Compare" class="cwc_icon"><i class="fa fa-refresh"
                            aria-hidden="true"></i></a></div>
                    </div>
                  </div>
                  <img src="../assets/images/poster_images2/poster6.png" class="poster_image" alt="...">
                  <div class="card-body py-4">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="">
                        <span class="poster_price">৳ 1,235</span>
                        <del class="poster_del_price">৳ 1,495</del>
                      </div>
                      <div>
                        <a href="" class="customize">Customize</a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!-- end single poster -->
            <!-- single poster -->
            <div class="col mb-4 mix wedding">
              <a href="#" class="single_poster_card position-relative">
                <div class="card shadow-sm position-relative overflow-hidden" id="poster_card" style="width: 19rem;">
                  <div class="parchentage_content">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="parchen shadow-sm"> -10%</div>
                      <div class="star shadow-sm"><i class="fa fa-star-o" aria-hidden="true"></i> <span>120k</span>
                      </div>
                    </div>
                  </div>

                  <div class="cwc">
                    <div class="cwc_icon_content d-flex flex-column align-items-center gap-4">
                      <div> <a href="" title="Add To Cart" class="cwc_icon"><i class="fa fa-shopping-cart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Wishlist" class="cwc_icon"><i class="fa fa-heart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Compare" class="cwc_icon"><i class="fa fa-refresh"
                            aria-hidden="true"></i></a></div>
                    </div>
                  </div>
                  <img src="../assets/images/poster_images2/poster6.png" class="poster_image" alt="...">
                  <div class="card-body py-4">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="">
                        <span class="poster_price">৳ 1,235</span>
                        <del class="poster_del_price">৳ 1,495</del>
                      </div>
                      <div>
                        <a href="" class="customize">Customize</a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!-- end single poster -->

            <!-- single poster -->
            <div class="col mb-4 mix family">
              <a href="#" class="single_poster_card position-relative">
                <div class="card shadow-sm position-relative overflow-hidden" id="poster_card" style="width: 19rem;">
                  <div class="parchentage_content">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="parchen shadow-sm"> -10%</div>
                      <div class="star shadow-sm"><i class="fa fa-star-o" aria-hidden="true"></i> <span>120k</span>
                      </div>
                    </div>
                  </div>

                  <div class="cwc">
                    <div class="cwc_icon_content d-flex flex-column align-items-center gap-4">
                      <div> <a href="" title="Add To Cart" class="cwc_icon"><i class="fa fa-shopping-cart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Wishlist" class="cwc_icon"><i class="fa fa-heart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Compare" class="cwc_icon"><i class="fa fa-refresh"
                            aria-hidden="true"></i></a></div>
                    </div>
                  </div>
                  <img src="../assets/images/poster_images2/poster2.png" class="poster_image" alt="...">
                  <div class="card-body py-4">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="">
                        <span class="poster_price">৳ 1,235</span>
                        <del class="poster_del_price">৳ 1,495</del>
                      </div>
                      <div>
                        <a href="" class="customize">Customize</a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!-- end single poster -->
            <!-- single poster -->
            <div class="col mb-4 mix family">
              <a href="#" class="single_poster_card position-relative">
                <div class="card shadow-sm position-relative overflow-hidden" id="poster_card" style="width: 19rem;">
                  <div class="parchentage_content">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="parchen shadow-sm"> -10%</div>
                      <div class="star shadow-sm"><i class="fa fa-star-o" aria-hidden="true"></i> <span>120k</span>
                      </div>
                    </div>
                  </div>

                  <div class="cwc">
                    <div class="cwc_icon_content d-flex flex-column align-items-center gap-4">
                      <div> <a href="" title="Add To Cart" class="cwc_icon"><i class="fa fa-shopping-cart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Wishlist" class="cwc_icon"><i class="fa fa-heart"
                            aria-hidden="true"></i></a></div>
                      <div><a href="" title="Add To Compare" class="cwc_icon"><i class="fa fa-refresh"
                            aria-hidden="true"></i></a></div>
                    </div>
                  </div>
                  <img src="../assets/images/poster_images2/poster3.png" class="poster_image" alt="...">
                  <div class="card-body py-4">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="">
                        <span class="poster_price">৳ 1,235</span>
                        <del class="poster_del_price">৳ 1,495</del>
                      </div>
                      <div>
                        <a href="" class="customize">Customize</a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!-- end single poster -->
          </div>
        </div>
      </div>
    </section>


    <!-- end poster section -->


    <!-- poster category section -->

    <section class="poster_category">
      <div class="container">
        <div class="row">
          <div class="col-lg-10">
            <h1 class="poster_section_title">Explore by <strong class="poster">Category</strong></h1>
          </div>
        </div>
        <div>
          <div class="owl-carousel owl-theme" id="poster_category">
            <div class="item">
              <a href="" style="text-decoration: none;">
                <div class="d-flex flex-column single_category">
                  <div><img src="../assets/images/category_icon/1.png" alt="" class="category_icon_image"></div>
                  <div class="category_name">StreetMap</div>
                </div>
              </a>
            </div>
            <div class="item ">
              <div class="d-flex flex-column single_category">
                <div><img src="../assets/images/category_icon/2.png" alt="" class="category_icon_image"></div>
                <div class="category_name">Wedding</div>
              </div>
            </div>
            <div class="item ">
              <div class="d-flex flex-column single_category">
                <div><img src="../assets/images/category_icon/3.png" alt="" class="category_icon_image"></div>
                <div class="category_name">StarMap</div>
              </div>
            </div>
            <div class="item ">
              <div class="d-flex flex-column single_category">
                <div><img src="../assets/images/category_icon/4.png" alt="" class="category_icon_image"></div>
                <div class="category_name">Family</div>
              </div>
            </div>
            <div class="item ">
              <div class="d-flex flex-column single_category">
                <div><img src="../assets/images/category_icon/5.png" alt="" class="category_icon_image"></div>
                <div class="category_name">Couple</div>
              </div>
            </div>
            <div class="item">
              <div class="d-flex flex-column single_category">
                <div><img src="../assets/images/category_icon/1.png" alt="" class="category_icon_image"></div>
                <div class="category_name">StreetMap</div>
              </div>
            </div>
            <div class="item">
              <div class="d-flex flex-column single_category">
                <div><img src="../assets/images/category_icon/1.png" alt="" class="category_icon_image"></div>
                <div class="category_name">StreetMap</div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 pagination_button d-none d-md-block">
            <button class="prev-btn">
              <img src="../assets/images/arrow-left.png" alt="" class="left_button">
            </button>
            <button class="next-btn">
              <img src="../assets/images/arrow-right.png" alt="" class="right_button">
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- end poster category section -->
    <section class="exclusive_poster_section">
      <div class="overlay_exclusive">
        <div class="container">
          <div>
            <h1 class="exclusive_tit">Exclusive Template</h1>
            <p class="exclusive_sub_tit">Unique templates for special moments.</p>
          </div>
          <!-- exclusive poster with coursel -->
          <div>
            <div class="owl-carousel owl-theme" id="exclusive_item">
              <div class="item">
                <a href="#" class="single_poster_card position-relative">
                  <div class="card shadow-sm position-relative overflow-hidden" id="poster_card" style="width: 19rem;">
                    <div class="parchentage_content">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="parchen shadow-sm"> -10%</div>
                        <div class="exclusive shadow-sm"><i class="fa fa-star-o text-primary" aria-hidden="true"></i>
                          <span>120k</span>
                        </div>
                      </div>
                    </div>

                    <div class="cwc">
                      <div class="cwc_icon_content d-flex flex-column align-items-center gap-4">
                        <div> <a href="" title="Add To Cart" class="cwc_icon"><i class="fa fa-shopping-cart"
                              aria-hidden="true"></i></a></div>
                        <div><a href="" title="Add To Wishlist" class="cwc_icon"><i class="fa fa-heart"
                              aria-hidden="true"></i></a></div>
                        <div><a href="" title="Add To Compare" class="cwc_icon"><i class="fa fa-refresh"
                              aria-hidden="true"></i></a></div>
                      </div>
                    </div>
                    <img src="../assets/images/exclusive/1.png" class="poster_image" alt="...">
                    <div class="card-body py-4">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="">
                          <span class="poster_price">৳ 1,235</span>
                          <del class="poster_del_price">৳ 1,495</del>
                        </div>
                        <div>
                          <a href="" class="customize">Customize</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="item">
                <a href="#" class="single_poster_card position-relative">
                  <div class="card shadow-sm position-relative overflow-hidden" id="poster_card" style="width: 19rem;">
                    <div class="parchentage_content">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="parchen shadow-sm"> -10%</div>
                        <div class="exclusive shadow-sm"><i class="fa fa-star-o text-primary" aria-hidden="true"></i>
                          <span>120k</span>
                        </div>
                      </div>
                    </div>

                    <div class="cwc">
                      <div class="cwc_icon_content d-flex flex-column align-items-center gap-4">
                        <div> <a href="" title="Add To Cart" class="cwc_icon"><i class="fa fa-shopping-cart"
                              aria-hidden="true"></i></a></div>
                        <div><a href="" title="Add To Wishlist" class="cwc_icon"><i class="fa fa-heart"
                              aria-hidden="true"></i></a></div>
                        <div><a href="" title="Add To Compare" class="cwc_icon"><i class="fa fa-refresh"
                              aria-hidden="true"></i></a></div>
                      </div>
                    </div>
                    <img src="../assets/images/exclusive/2.png" class="poster_image" alt="...">
                    <div class="card-body py-4">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="">
                          <span class="poster_price">৳ 1,235</span>
                          <del class="poster_del_price">৳ 1,495</del>
                        </div>
                        <div>
                          <a href="" class="customize">Customize</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="item ">
                <a href="#" class="single_poster_card position-relative">
                  <div class="card shadow-sm position-relative overflow-hidden" id="poster_card" style="width: 19rem;">
                    <div class="parchentage_content">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="parchen shadow-sm"> -10%</div>
                        <div class="exclusive shadow-sm"><i class="fa fa-star-o text-primary" aria-hidden="true"></i>
                          <span>120k</span>
                        </div>
                      </div>
                    </div>

                    <div class="cwc">
                      <div class="cwc_icon_content d-flex flex-column align-items-center gap-4">
                        <div> <a href="" title="Add To Cart" class="cwc_icon"><i class="fa fa-shopping-cart"
                              aria-hidden="true"></i></a></div>
                        <div><a href="" title="Add To Wishlist" class="cwc_icon"><i class="fa fa-heart"
                              aria-hidden="true"></i></a></div>
                        <div><a href="" title="Add To Compare" class="cwc_icon"><i class="fa fa-refresh"
                              aria-hidden="true"></i></a></div>
                      </div>
                    </div>
                    <img src="../assets/images/exclusive/3.png" class="poster_image" alt="...">
                    <div class="card-body py-4">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="">
                          <span class="poster_price">৳ 1,235</span>
                          <del class="poster_del_price">৳ 1,495</del>
                        </div>
                        <div>
                          <a href="" class="customize">Customize</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="item ">
                <a href="#" class="single_poster_card position-relative">
                  <div class="card shadow-sm position-relative overflow-hidden" id="poster_card" style="width: 19rem;">
                    <div class="parchentage_content">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="parchen shadow-sm"> -10%</div>
                        <div class="exclusive shadow-sm"><i class="fa fa-star-o text-primary" aria-hidden="true"></i>
                          <span>120k</span>
                        </div>
                      </div>
                    </div>

                    <div class="cwc">
                      <div class="cwc_icon_content d-flex flex-column align-items-center gap-4">
                        <div> <a href="" title="Add To Cart" class="cwc_icon"><i class="fa fa-shopping-cart"
                              aria-hidden="true"></i></a></div>
                        <div><a href="" title="Add To Wishlist" class="cwc_icon"><i class="fa fa-heart"
                              aria-hidden="true"></i></a></div>
                        <div><a href="" title="Add To Compare" class="cwc_icon"><i class="fa fa-refresh"
                              aria-hidden="true"></i></a></div>
                      </div>
                    </div>
                    <img src="../assets/images/poster_images2/poster2.png" class="poster_image" alt="...">
                    <div class="card-body py-4">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="">
                          <span class="poster_price">৳ 1,235</span>
                          <del class="poster_del_price">৳ 1,495</del>
                        </div>
                        <div>
                          <a href="" class="customize">Customize</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

            </div>
            <div class="pagination_button d-none d-md-block">
              <button class="prev-btn-exclusive">
                <img src="../assets/images/arrow-left.png" alt="" class="left_button">
              </button>
              <button class="next-btn-exclusive">
                <img src="../assets/images/arrow-right.png" alt="" class="right_button">
              </button>
            </div>
          </div>

          <div>
            <p class="exclusive_bottom">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
        </div>
      </div>
    </section>
    <!-- exclusive poster section start  -->

    <!-- poster review section -->
    <section class="customer_review">
      <div class="container">
        <div class="row">
          <div class="col-lg-10">
            <h1 class="poster_section_title">Customer Poster <strong class="poster">Review</strong></h1>
          </div>
        </div>
      </div>

      <!-- owl carousel start  -->
      <div>

        <div class="owl-carousel owl-theme" id="customer_review">
          <div class="item card shadow-lg customer_review_cart">
            <div class="">
              <div class="card-body mt-4 card_body">
                <div class="date d-flex align-items-end justify-content-end">01.01.2023</div>
                <div class="review_name d-flex align-items-center gap-2">
                  <img src="../assets/images/user.png" alt="" class="customer_image">
                  <p class="customer_name">JHON SMITH</p>
                </div>
                <div class="review_star">
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                </div>
                <div>
                  <p class="review_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="item card shadow customer_review_cart">
            <div class="">
              <div class="card-body mt-4 card_body">
                <div class="date d-flex align-items-end justify-content-end">01.01.2023</div>
                <div class="review_name d-flex align-items-center gap-2">
                  <img src="../assets/images/user.png" alt="" class="customer_image">
                  <p class="customer_name">JHON SMITH</p>
                </div>
                <div class="review_star">
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                </div>
                <div>
                  <p class="review_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="item card shadow customer_review_cart">
            <div class="">
              <div class="card-body mt-4 card_body">
                <div class="date d-flex align-items-end justify-content-end">01.01.2023</div>
                <div class="review_name d-flex align-items-center gap-2">
                  <img src="../assets/images/user.png" alt="" class="customer_image">
                  <p class="customer_name">JHON SMITH</p>
                </div>
                <div class="review_star">
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                </div>
                <div>
                  <p class="review_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="item card shadow customer_review_cart">
            <div class="">
              <div class="card-body mt-4 card_body">
                <div class="date d-flex align-items-end justify-content-end">01.01.2023</div>
                <div class="review_name d-flex align-items-center gap-2">
                  <img src="../assets/images/user.png" alt="" class="customer_image">
                  <p class="customer_name">JHON SMITH</p>
                </div>
                <div class="review_star">
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <span><i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                </div>
                <div>
                  <p class="review_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- end poster section -->

    <!-- influential customer review -->
    <section class="influential_customer_review">
      <div class="container">
        <h1 class="poster_section_title">Influential Personality <strong class="poster">Poster Review</strong></h1>
      </div>
      <!-- carousel start  -->
      <div class="position-relative">
        <div class="owl-carousel owl-theme" id="influential_customer_review">
          <div class="item">
            <div class="card shadow influential_card">
              <img class="influential_image" src="../assets/images/influential/in3.png" alt="">
              <div class="card-body">
                <div class="col">
                  <p class="influential_name">John Smith</p>
                  <div class="influential_star">
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                  </div>
                  <p class="influential_review_text">
                    Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat.
                  </p>
                  <div class="influential_learn_more">
                    <a href="" class="">Learn More...</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="card shadow influential_card">
              <img class="influential_image" src="../assets/images/influential/in1.png" alt="">
              <div class="card-body">
                <div class="col">
                  <p class="influential_name">John Smith</p>
                  <div class="influential_star">
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                  </div>
                  <p class="influential_review_text">
                    Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat.
                  </p>
                  <div class="influential_learn_more">
                    <a href="" class="">Learn More...</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="card shadow influential_card">
              <img class="influential_image" src="../assets/images/influential/in2.png" alt="">
              <div class="card-body">
                <div class="col">
                  <p class="influential_name">John Smith</p>
                  <div class="influential_star">
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                  </div>
                  <p class="influential_review_text">
                    Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat.
                  </p>
                  <div class="influential_learn_more">
                    <a href="" class="">Learn More...</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="card shadow influential_card">
              <img class="influential_image" src="../assets/images/influential/in3.png" alt="">
              <div class="card-body">
                <div class="col">
                  <p class="influential_name">John Smith</p>
                  <div class="influential_star">
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                  </div>
                  <p class="influential_review_text">
                    Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat.
                  </p>
                  <div class="influential_learn_more">
                    <a href="" class="">Learn More...</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="card shadow influential_card">
              <img class="influential_image" src="../assets/images/influential/in1.png" alt="">
              <div class="card-body">
                <div class="col">
                  <p class="influential_name">John Smith</p>
                  <div class="influential_star">
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                    </span>
                  </div>
                  <p class="influential_review_text">
                    Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat.
                  </p>
                  <div class="influential_learn_more">
                    <a href="" class="">Learn More...</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pagination_button d-none d-md-block">
          <button class="prev-btn-influential">
            <img src="../assets/images/arrow-left.png" alt="" class="left_button">
          </button>
          <button class="next-btn-influential">
            <img src="../assets/images/arrow-right.png" alt="" class="right_button">
          </button>
        </div>
      </div>

    </section>
    <!-- end influential customer review -->

    <!-- gallery section start -->
    <section class="gallery">
      <div class="container">
        <h1 class="poster_section_title">Our <strong class="poster">Gallery</strong></h1>
      </div>
      <div class="row mx-3 gallery_row">
        <div class="col-md-3">
          <div class="thumbnail position-relative single_gallery">
            <a href="">
              <img src="../assets/images/gallery/1.png" alt="Lights" style="width:100%;height: 250px;">
              <div class="galler_user">
                <div class="d-flex align-items-center gap-2">
                  <img src="../assets/images/gallery/gallery_user.png" alt="" class="gallery_user_image">
                  <p class="gallery_user_name">JHON SMITH</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="thumbnail position-relative single_gallery">
            <a href="">
              <img src="../assets/images/gallery/2.png" alt="Lights" style="width:100%;height: 250px;">
              <div class="galler_user">
                <div class="d-flex align-items-center gap-2">
                  <img src="../assets/images/gallery/gallery_user.png" alt="" class="gallery_user_image">
                  <p class="gallery_user_name">JHON SMITH</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="thumbnail position-relative single_gallery">
            <a href="">
              <img src="../assets/images/gallery/3.png" alt="Lights" style="width:100%;height: 250px;">
              <div class="galler_user">
                <div class="d-flex align-items-center gap-2">
                  <img src="../assets/images/gallery/gallery_user.png" alt="" class="gallery_user_image">
                  <p class="gallery_user_name">JHON SMITH</p>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-md-3">
          <div class="thumbnail position-relative single_gallery">
            <a href="">
              <img src="../assets/images/gallery/4.png" alt="Lights" style="width:100%;height: 250px;">
              <div class="galler_user">
                <div class="d-flex align-items-center gap-2">
                  <img src="../assets/images/gallery/gallery_user.png" alt="" class="gallery_user_image">
                  <p class="gallery_user_name">JHON SMITH</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="thumbnail position-relative single_gallery">
            <a href="">
              <img src="../assets/images/gallery/1.png" alt="Lights" style="width:100%;height: 250px;">
              <div class="galler_user">
                <div class="d-flex align-items-center gap-2">
                  <img src="../assets/images/gallery/gallery_user.png" alt="" class="gallery_user_image">
                  <p class="gallery_user_name">JHON SMITH</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="thumbnail position-relative single_gallery">
            <a href="">
              <img src="../assets/images/gallery/2.png" alt="Lights" style="width:100%;height: 250px;">
              <div class="galler_user">
                <div class="d-flex align-items-center gap-2">
                  <img src="../assets/images/gallery/gallery_user.png" alt="" class="gallery_user_image">
                  <p class="gallery_user_name">JHON SMITH</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="thumbnail position-relative single_gallery">
            <a href="">
              <img src="../assets/images/gallery/3.png" alt="Lights" style="width:100%;height: 250px;">
              <div class="galler_user">
                <div class="d-flex align-items-center gap-2">
                  <img src="../assets/images/gallery/gallery_user.png" alt="" class="gallery_user_image">
                  <p class="gallery_user_name">JHON SMITH</p>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-md-3">
          <div class="thumbnail position-relative single_gallery">
            <a href="">
              <img src="../assets/images/gallery/4.png" alt="Lights" style="width:100%;height: 250px;">
              <div class="galler_user">
                <div class="d-flex align-items-center gap-2">
                  <img src="../assets/images/gallery/gallery_user.png" alt="" class="gallery_user_image">
                  <p class="gallery_user_name">JHON SMITH</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="thumbnail position-relative single_gallery">
            <a href="">
              <img src="../assets/images/gallery/1.png" alt="Lights" style="width:100%;height: 250px;">
              <div class="galler_user">
                <div class="d-flex align-items-center gap-2">
                  <img src="../assets/images/gallery/gallery_user.png" alt="" class="gallery_user_image">
                  <p class="gallery_user_name">JHON SMITH</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="thumbnail position-relative single_gallery">
            <a href="">
              <img src="../assets/images/gallery/2.png" alt="Lights" style="width:100%;height: 250px;">
              <div class="galler_user">
                <div class="d-flex align-items-center gap-2">
                  <img src="../assets/images/gallery/gallery_user.png" alt="" class="gallery_user_image">
                  <p class="gallery_user_name">JHON SMITH</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="thumbnail position-relative single_gallery">
            <a href="">
              <img src="../assets/images/gallery/3.png" alt="Lights" style="width:100%;height: 250px;">
              <div class="galler_user">
                <div class="d-flex align-items-center gap-2">
                  <img src="../assets/images/gallery/gallery_user.png" alt="" class="gallery_user_image">
                  <p class="gallery_user_name">JHON SMITH</p>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-md-3">
          <div class="thumbnail position-relative single_gallery">
            <a href="">
              <img src="../assets/images/gallery/4.png" alt="Lights" style="width:100%;height: 250px;">
              <div class="galler_user">
                <div class="d-flex align-items-center gap-2">
                  <img src="../assets/images/gallery/gallery_user.png" alt="" class="gallery_user_image">
                  <p class="gallery_user_name">JHON SMITH</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <!--end gallery section  -->

    <!-- process section start  -->
    <section class="process">
      <div class="container">
        <h1 class="poster_section_title">Step-by-Step Process of <strong class="poster">Customization</strong></h1>

        <div>
          <img src="../assets/images/process.png" alt="" class="process_image">
        </div>
      </div>
    </section>
    <!-- end process section -->


    <!--  service section start -->
    <section class="service">
      <div class="container">
        <h1 class="poster_section_title mb-4">Why We Are <strong class="poster">the Best</strong></h1>

        <div class="row mt-4 service_row">
          <div class="col-md-4 mb-4">
            <div class="single_service m-auto flex-column align-items-center justify-content-center">
              <div><img src="../assets/images/services/mobile.png" alt="" class="servie_icon"></div>
              <h3 class="service_title">Updated with Real-Time Order Tracking</h3>
              <p class="service_description">Stay informed and in control with our live order updates. Track the
                progress of your poster and know exactly which stage it's in.</p>
            </div>
          </div>
          <div class="col-md-4 mb-4">
            <div class="single_service m-auto flex-column align-items-center justify-content-center">
              <div><img src="../assets/images/services/car.png" alt="" class="servie_icon"></div>
              <h3 class="service_title">Convenient and Complimentary Delivery</h3>
              <p class="service_description">Enjoy the convenience of free doorstep delivery, with flexible options such
                as standard shipping, express delivery, or the choice of in-store pickup.
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-4">
            <div class="single_service m-auto flex-column align-items-center justify-content-center">
              <div><img src="../assets/images/services/face.png" alt="" class="servie_icon"></div>
              <h3 class="service_title">Your Satisfaction is Our Priority</h3>
              <p class="service_description">Your happiness is paramount to us. If for any reason you're not satisfied,
                we offer a hassle-free refund or reprint of your order.</p>
            </div>
          </div>

          <div class="col-md-4 mb-4">
            <div class="single_service m-auto flex-column align-items-center justify-content-center">
              <div><img src="../assets/images/services/customize.png" alt="" class="servie_icon"></div>
              <h3 class="service_title">Endless Possibilities with Customizable Templates</h3>
              <p class="service_description">Find boundless inspiration with our extensive collection of free and fully
                customizable print templates tailored to suit your unique style.
              </p>
            </div>
          </div>

          <div class="col-md-4 mb-4">
            <div class="single_service m-auto flex-column align-items-center justify-content-center">
              <div><img src="../assets/images/services/badge.png" alt="" class="servie_icon"></div>
              <h3 class="service_title">Pioneering Customized Poster Solutions in Bangladesh</h3>
              <p class="service_description">We proudly stand as the first and foremost provider of personalized poster
                solutions in Bangladesh.
              </p>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- service section end  -->


    <!-- our blogs section start  -->

    <section class="blog">
      <div class="container">
        <h1 class="poster_section_title mb-4">Our <strong class="poster">Blogs</strong></h1>

        <!-- owl carousel start -->
        <div class="owl-carousel owl-theme" id="our_blog">
          <div class="item">
            <div class="card blog_card shadow-lg">
              <img class="card-img-top" src="../assets/images/blog/blog3.png" alt="Card image cap">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="blog_eye d-flex align-items-center gap-2">
                    <span>
                      <img src="../assets/images/eye.png" alt="" class="blog_eye">
                    </span>
                    <span class="total_view">124</span>
                  </div>
                  <div class="reading">
                    5 min. for reading
                  </div>
                </div>
                <h1 class="blog_name">Blog Name</h1>
                <p class="blog_short_desription">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore....
                </p>
                <p class="blog_date text-primary">May, 12th</p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="card blog_card shadow-lg">
              <img class="card-img-top" src="../assets/images/blog/blog1.png" alt="Card image cap">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="blog_eye d-flex align-items-center gap-2">
                    <span>
                      <img src="../assets/images/eye.png" alt="" class="blog_eye">
                    </span>
                    <span class="total_view">124</span>
                  </div>
                  <div class="reading">
                    5 min. for reading
                  </div>
                </div>
                <h1 class="blog_name">Blog Name</h1>
                <p class="blog_short_desription">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore....
                </p>
                <p class="blog_date text-primary">May, 12th</p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="card blog_card shadow-lg">
              <img class="card-img-top" src="../assets/images/blog/blog2.png" alt="Card image cap">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="blog_eye d-flex align-items-center gap-2">
                    <span>
                      <img src="../assets/images/eye.png" alt="" class="blog_eye">
                    </span>
                    <span class="total_view">124</span>
                  </div>
                  <div class="reading">
                    5 min. for reading
                  </div>
                </div>
                <h1 class="blog_name">Blog Name</h1>
                <p class="blog_short_desription">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore....
                </p>
                <p class="blog_date text-primary">May, 12th</p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="card blog_card shadow-lg">
              <img class="card-img-top" src="../assets/images/blog/blog3.png" alt="Card image cap">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="blog_eye d-flex align-items-center gap-2">
                    <span>
                      <img src="../assets/images/eye.png" alt="" class="blog_eye">
                    </span>
                    <span class="total_view">124</span>
                  </div>
                  <div class="reading">
                    5 min. for reading
                  </div>
                </div>
                <h1 class="blog_name">Blog Name</h1>
                <p class="blog_short_desription">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore....
                </p>
                <p class="blog_date text-primary">May, 12th</p>
              </div>
            </div>
          </div>
        </div>


      </div>
    </section>

    <!-- end our blog -->

    <!-- question section -->
    <section class="question">
      <div class="container">
        <h1 class="poster_section_title mb-4">Frequently Asked <strong class="poster"> Questions</strong></h1>


        <div class="row question_row">
          <div class="accordion row" id="accordionExample">
            <div class="col-md-6">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#question1"
                    aria-expanded="true" aria-controls="collapseOne">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                  </button>
                </h2>
                <div id="question1" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse
                    plugin adds the appropriate classes that we use to style each element. These classes control the
                    overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of
                    this with custom CSS or overriding our default variables. It's also worth noting that just about any
                    HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#question2" aria-expanded="false" aria-controls="question2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                  </button>
                </h2>
                <div id="question2" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <strong>This is the second item's accordion body.</strong> It is hidden by default, until the
                    collapse plugin adds the appropriate classes that we use to style each element. These classes
                    control the overall appearance, as well as the showing and hiding via CSS transitions. You can
                    modify any of this with custom CSS or overriding our default variables. It's also worth noting that
                    just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit
                    overflow.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#question3" aria-expanded="false" aria-controls="collapseThree">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                  </button>
                </h2>
                <div id="question3" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <strong>This is the third item's accordion body.</strong> It is hidden by default, until the
                    collapse plugin adds the appropriate classes that we use to style each element. These classes
                    control the overall appearance, as well as the showing and hiding via CSS transitions. You can
                    modify any of this with custom CSS or overriding our default variables. It's also worth noting that
                    just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit
                    overflow.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#question4" aria-expanded="false" aria-controls="collapseThree">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                  </button>
                </h2>
                <div id="question4" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <strong>This is the third item's accordion body.</strong> It is hidden by default, until the
                    collapse plugin adds the appropriate classes that we use to style each element. These classes
                    control the overall appearance, as well as the showing and hiding via CSS transitions. You can
                    modify any of this with custom CSS or overriding our default variables. It's also worth noting that
                    just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit
                    overflow.
                  </div>
                </div>
              </div>
            </div>



            <div class="col-md-6">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#question5"
                    aria-expanded="true" aria-controls="collapseOne">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                  </button>
                </h2>
                <div id="question5" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse
                    plugin adds the appropriate classes that we use to style each element. These classes control the
                    overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of
                    this with custom CSS or overriding our default variables. It's also worth noting that just about any
                    HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#question6" aria-expanded="false" aria-controls="collapseTwo">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                  </button>
                </h2>
                <div id="question6" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <strong>This is the second item's accordion body.</strong> It is hidden by default, until the
                    collapse plugin adds the appropriate classes that we use to style each element. These classes
                    control the overall appearance, as well as the showing and hiding via CSS transitions. You can
                    modify any of this with custom CSS or overriding our default variables. It's also worth noting that
                    just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit
                    overflow.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#question7" aria-expanded="false" aria-controls="collapseThree">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                  </button>
                </h2>
                <div id="question7" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <strong>This is the third item's accordion body.</strong> It is hidden by default, until the
                    collapse plugin adds the appropriate classes that we use to style each element. These classes
                    control the overall appearance, as well as the showing and hiding via CSS transitions. You can
                    modify any of this with custom CSS or overriding our default variables. It's also worth noting that
                    just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit
                    overflow.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#question8" aria-expanded="false" aria-controls="collapseThree">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                  </button>
                </h2>
                <div id="question8" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <strong>This is the third item's accordion body.</strong> It is hidden by default, until the
                    collapse plugin adds the appropriate classes that we use to style each element. These classes
                    control the overall appearance, as well as the showing and hiding via CSS transitions. You can
                    modify any of this with custom CSS or overriding our default variables. It's also worth noting that
                    just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit
                    overflow.
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section>
    <!-- end question section -->


    <!-- delivary map section -->
    <section class="delivery">
      <div class="container">
        <h1 class="poster_section_title mb-4">Delivery <strong class="poster">Map</strong></h1>

        <div>
          <!--Google map-->
          <div class="map-responsive">
            <iframe
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA0s1a7phLN0iaD6-UE7m4qP-z21pH0eSc&q=Eiffel+Tower+Paris+France"
              width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
          </div>
          <!--Google Maps-->
        </div>
      </div>
    </section>
    <!-- end delivery section -->
  </main>

</template>


<script>
  export default {
    name: 'Homepage',
    components: {

    },


  }
</script>