<template>
  <div class="">
    <div class="row">
      <div class="col-md-6">
        <div>
          <div id="celestial-map"></div>

        </div>
      </div>


      <div class="col-md-6">
        <div class="py-3" style="border:1px solid black">
          <form class="form-inline" action="/action_page.php">

            <div class="row">
              <div class="col-md-12">
                <div class="d-flex">
                  <div class="d-flex">
                    <label for="" class="m-2">Location</label>
                    <input type="number" class="form-control m-1" placeholder="Latitude"
                      v-model="this.starmapApi.center.lat">
                    <input type="number" class="form-control m-1" placeholder="Longitude"
                      v-model="this.starmapApi.center.long">
                  </div>

                  <div class="d-flex">
                    <label for="" class="m-2">Date/Time</label>
                    <input type="date" class="form-control m-1" placeholder="Latitude">
                    <input type="time" class="form-control m-1" placeholder="Latitude">

                  </div>
                </div>
              </div>

            </div>

            <hr>

            <div class="row">
              <div class="col-md-12">
                <div class="row d-flex">
                  <div class="mx-1">
                    <span><b>Stars</b> <input type="checkbox" v-model="this.starmapApi.stars.show"></span>
                    <span>down to magnitude <input type="number" v-model="this.starmapApi.stars.limit" class="cinput"
                        :disabled="!this.starmapApi.stars.show"></span>
                    <span>with spectral colors <input type="checkbox" v-model="this.starmapApi.stars.colors"
                        :disabled="!this.starmapApi.stars.show"></span>
                    <span>or default color <input type="color" v-model="this.starmapApi.stars.style.fill" class="cinput"
                        :disabled="!this.starmapApi.stars.show"></span>
                  </div>
                </div>

                <div class="row d-flex">
                  <div class="mx-1">
                    <span>Stellar disk size: base
                      <input type="number" v-model="this.starmapApi.stars.size" class="cinput"
                        :disabled="!this.starmapApi.stars.show">
                    </span>
                    <span>
                      * e ^ (exponent
                      <input type="number" v-model="this.starmapApi.stars.exponent" class="cinput"
                        :disabled="!this.starmapApi.stars.show">
                      * (magnitude + 2)) [* adaptation]
                    </span>
                  </div>
                </div>
              </div>
            </div>


            <hr>

            <div class="row">
              <div class="col-md-12">
                <div class="row d-flex">
                  <div class="mx-1">
                    <span><b>DSOs</b> <input type="checkbox" v-model="this.starmapApi.dsos.show"></span>
                    <span>down to mag <input type="number" v-model="this.starmapApi.dsos.limit"
                        :disabled="!this.starmapApi.dsos.show" class="cinput" id=""></span>
                    <span>with symbol colors <input type="checkbox" v-model="this.starmapApi.dsos.colors"
                        :disabled="!this.starmapApi.dsos.show"> </span>
                    <span>or default color <input type="color" class="cinput" v-model="this.starmapApi.dsos.style.fill"
                        :disabled="!this.starmapApi.dsos.show"> </span>
                    <span>DSO symbol size: (base <input type="number" v-model="this.starmapApi.dsos.size" class="cinput"
                        :disabled="!this.starmapApi.dsos.show"></span>
                    <span>* 2 [* adaptation] - magnitude) ^ exponent <input type="number"
                        v-model="this.starmapApi.dsos.exponent" class="cinput"
                        :disabled="!this.starmapApi.dsos.show"></span>
                  </div>
                </div>
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col-md-12">
                <div class="row d-flex">
                  <div class="mx-1">
                    <span><b>Constellations </b> <input type="checkbox" v-model="this.starmapApi.constellations.names">
                    </span>
                    <span>lines <input type="checkbox" class="cinput" v-model="this.starmapApi.constellations.lines"
                        :disabled="!this.starmapApi.constellations.names"></span>
                    <span>color <input type="color" class="cinput"
                        v-model="this.starmapApi.constellations.lineStyle.stroke"
                        :disabled="!this.starmapApi.constellations.names"></span>
                    <span>boundaries <input type="checkbox" class="cinput" v-model="this.starmapApi.constellations.bounds"
                        :disabled="!this.starmapApi.constellations.names"></span>
                    <span>color <input type="color" class="cinput"
                        v-model="this.starmapApi.constellations.boundStyle.stroke"
                        :disabled="!this.starmapApi.constellations.names"></span>
                  </div>
                </div>
              </div>
            </div>

            <hr>


            <div class="row">
              <div class="col-md-12">
                <div class="row d-flex">
                  <div class="mx-1">
                    <span><b>Lines </b> </span>
                    <span>Graticule <input type="checkbox" v-model="this.starmapApi.lines.graticule.show"></span>
                    <span>Equator <input type="checkbox" v-model="this.starmapApi.lines.equatorial.show"></span>
                    <span>Ecliptic <input type="checkbox" v-model="this.starmapApi.lines.ecliptic.show"></span>
                    <span>Galactic plane <input type="checkbox" v-model="this.starmapApi.lines.galactic.show"></span>
                    <span>Supergalactic plane <input type="checkbox"
                        v-model="this.starmapApi.lines.supergalactic.show"></span>
                  </div>
                </div>
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col-md-12">
                <div class="row d-flex">
                  <div class="mx-1">
                    <span><b>Other </b> </span>
                    <span>Milky Way <input type="checkbox" v-model="this.starmapApi.mw.show"></span>
                    <span>color <input type="color" class="cinput" v-model="this.starmapApi.mw.style.fill"
                        :disabled="!this.starmapApi.mw.show"></span>
                    <span>opacity <input type="number" class="cinput" v-model="this.starmapApi.mw.style.opacity"
                        :disabled="!this.starmapApi.mw.show"> </span>
                    <br>
                    <span>Background Color <input type="color" class="cinput" v-model="this.starmapApi.background.fill">
                    </span>
                    <span>opacity <input type="number" class="cinput" v-model="this.starmapApi.background.opacity"></span>
                  </div>
                </div>
              </div>
            </div>


          </form>
        </div>
      </div>



    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  components: {
    InlineSvg,
  },
  data() {
    return {

      starmapApi: {


        center: {
          long: 0, lat: 0, orient: 0
        },
        stars: {
          show: true,    // Show stars
          limit: 6,      // Show only stars brighter than limit magnitude
          colors: true,  // Show stars in spectral colors, if not use default color
          style: {

            fill: "#ffffff",
            opacity: 1

          },

          size: 7,       // Maximum size (radius) of star circle in pixels
          exponent: -0.28, // Scale exponent for star size, larger = more linear
          data: 'stars.6.json' // Data source for stellar data, 

        },
        dsos: {
          show: true,    // Show Deep Space Objects 
          limit: 6,      // Show only DSOs brighter than limit magnitude
          colors: true,  // // Show DSOs in symbol colors if true, use style setting below if false
          style: {
            fill: "#cccccc",
            stroke: "#cccccc", width: 2, opacity: 1
          }, // Default style for dsos

          size: null,    // Optional seperate scale size for DSOs, null = stars.size
          exponent: 1.4, // Scale exponent for DSO size, larger = more non-linear
          data: 'dsos.bright.json', // Data source for DSOs, 

        },
        constellations: {
          names: true,      // Show constellation names 
          namesType: "iau", // Type of name Latin (iau, default), 3 letter designation (desig) or other language (see list below)
          nameStyle: {
            fill: "#cccc99", align: "center", baseline: "middle",
            font: ["14px Helvetica, Arial, sans-serif",  // Style for constellations
              "12px Helvetica, Arial, sans-serif",  // Different fonts for diff.
              "11px Helvetica, Arial, sans-serif"]
          },// ranked constellations

          lines: true,   // Show constellation lines, style below
          lineStyle: {
            stroke: "#cccccc",
            width: 1, opacity: 0.6
          },
          bounds: false, // Show constellation boundaries, style below
          boundStyle: { stroke: "#cccc00", width: 0.5, opacity: 0.8, dash: [2, 4] }
        },
        mw: {
          show: false,     // Show Milky Way as filled multi-polygon outlines 
          style: { fill: "#ffffff", opacity: 0.15 }  // Style for MW layers
        },
        lines: {  // Display & styles for graticule & some planes
          graticule: {
            show: true, stroke: "#cccccc", width: 0.6, opacity: 0.8,
            // grid values: "outline", "center", or [lat,...] specific position
            lon: { pos: [""], fill: "#eee", font: "10px Helvetica, Arial, sans-serif" },
            // grid values: "outline", "center", or [lon,...] specific position
            lat: { pos: [""], fill: "#eee", font: "10px Helvetica, Arial, sans-serif" }
          },
          equatorial: { show: true, stroke: "#aaaaaa", width: 1.3, opacity: 0.7 },
          ecliptic: { show: false, stroke: "#66cc66", width: 1.3, opacity: 0.7 },
          galactic: { show: false, stroke: "#cc6666", width: 1.3, opacity: 0.7 },
          supergalactic: { show: false, stroke: "#cc66cc", width: 1.3, opacity: 0.7 }
        },
        background: {        // Background style
          fill: "#000000",   // Area fill
          opacity: 1,
          stroke: "#000000", // Outline
          width: 1.5
        },
      },
    };
  },
  watch: {
    'starmapApi.stars.show': 'updateStarMap',
    'starmapApi.stars.limit': 'updateStarMap',
    'starmapApi.stars.colors': 'updateStarMap',
    'starmapApi.stars.style.fill': 'updateStarMap',
    'starmapApi.stars.size': 'updateStarMap',
    'starmapApi.stars.exponent': 'updateStarMap',

    'starmapApi.dsos.show': 'updateStarMap',
    'starmapApi.dsos.exponent': 'updateStarMap',
    'starmapApi.dsos.size': 'updateStarMap',
    'starmapApi.dsos.style.fill': 'updateStarMap',
    'starmapApi.dsos.colors': 'updateStarMap',
    'starmapApi.dsos.limit': 'updateStarMap',

    'starmapApi.constellations.boundStyle.stroke': 'updateStarMap',
    'starmapApi.constellations.bounds': 'updateStarMap',
    'starmapApi.constellations.lineStyle.stroke': 'updateStarMap',
    'starmapApi.constellations.lines': 'updateStarMap',
    'starmapApi.constellations.names': 'updateStarMap',


    'starmapApi.lines.supergalactic.show': 'updateStarMap',
    'starmapApi.lines.galactic.show': 'updateStarMap',
    'starmapApi.lines.ecliptic.show': 'updateStarMap',
    'starmapApi.lines.equatorial.show': 'updateStarMap',
    'starmapApi.lines.graticule.show': 'updateStarMap',

    'starmapApi.mw.style.opacity': 'updateStarMap',
    'starmapApi.mw.style.fill': 'updateStarMap',
    'starmapApi.mw.show': 'updateStarMap',
    'starmapApi.background.opacity': 'updateStarMap',
    'starmapApi.background.fill': 'updateStarMap',



  },
  mounted() {
    // this.generateStarMap();
    this.customFunction();
    //  this.getDivHidden('celestial-form')
  },
  methods: {
    customFunction() {


      var config = {
        disableAnimations: false,
        form: false,
        formFields: {
          download: false
        },
        datapath: "/data/",

        // orientationfixed: true, 
        advanced: false,

        // center : [this.starmapApi.center.lang , this.starmapApi.center.lat, this.starmapApi.center.orient],
        stars: this.starmapApi.stars,
        dsos: this.starmapApi.dsos,
        constellations: this.starmapApi.constellations,
        mw: this.starmapApi.mw,
        lines: this.starmapApi.lines,
        background: this.starmapApi.background,

      }

      // Display map with the configuration above or any subset thereof
      Celestial.display(config);

    },
    updateStarMap() {
      // This method will be called whenever any watched property changes
      this.customFunction();
    },


    getDiv(id) {
      const el = document.getElementById(id);
      return el;
    },
    getDivHidden(id) {
      this.getDiv(id).style.display = 'none';
    },
    getDivShow(id) {
      this.getDiv(id).style.display = 'none';
    }

  },
};
</script>

<style scoped>.cinput {
  max-width: 40px;
}</style>