import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/pages/HomePage.vue'
import Config1 from '../views/pages/configurator_1.vue'
import Config2 from '../views/pages/configurator_2.vue'
import RatingIcon from '../views/pages/RatingIcon.vue'
import RatingIcon2 from '../views/pages/RatingIcon2.vue'
import Starmap from '../views/pages/Starmap.vue'
import Worldmap from '../views/pages/Worldmap.vue'
import StarmapForm from '../views/pages/StarmapForm.vue'

import Template1 from '../views/pages/Template1.vue'
import Template2 from '../views/pages/Template2.vue'
import Template3 from '../views/pages/Template3.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/configurator-1',
    name: 'config1',
    component: Config1
  },
  {
    path: '/configurator-2',
    name: 'config2',
    component: Config2
  },
  {
    path: '/svg',
    name: 'RatingIcon',
    component: RatingIcon
  },
  {
    path: '/svg2',
    name: 'RatingIcon2',
    component: RatingIcon2
  },
  {
    path: '/template-3/:formData',
    name: 'Template3',
    component: Template3,
    props: true
  },
  {
    path: '/template-1/:formData',
    name: 'Template1',
    component: Template1,
    props: true
  },
  {
    path: '/template-2/:formData',
    name: 'Template2',
    component: Template2,
    props: true
  },
  {
    path: '/starmap',
    name: 'Starmap',
    component: Starmap
  },
  {
    path: '/starmap-form',
    name: 'StarmapForm',
    component: StarmapForm
  },
  {
    path: '/worldmap',
    name: 'Worldmap',
    component: Worldmap
  },
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
