<template>
  <!-- <nav>
    
    <router-link to="/">Home</router-link> |
    <router-link to="/configurator-1">Config1</router-link> |
    <router-link to="/configurator-2">Config2</router-link> 
    
  </nav> -->

  <header>
    <nav class="navbar navbar-expand-lg navbar-light bg-light shadow">
      <div class="container py-2">
        <a class="navbar-brand logo" href="#">
          <img src="../public/images/logo.png" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
          <ul class="navbar-nav  mb-2 mb-lg-0 d-flex align-items-center gap-5">
            <li class="nav-item text-center navigation_item">
              <span> <img src="../public/images/navbar-icon/home.png" alt="" class="navigation_image"> </span>
              <router-link class="nav-link  navigation_item_text" aria-current="page" to="/">Home</router-link>
            </li>
            <li class="nav-item text-center navigation_item">
              <span> <img src="../public/images/navbar-icon/configurator.png" alt="" class="navigation_image"> </span>
              <router-link class="nav-link  navigation_item_text" aria-current="page" to="/configurator-1">Configurator</router-link>
            </li>
            <!-- <li class="nav-item text-center navigation_item">
              <span> <img src="../public/images/navbar-icon/configurator.png" alt="" class="navigation_image"> </span>
              <router-link class="nav-link  navigation_item_text" aria-current="page" to="/configurator-2">Configurator-2</router-link>
            </li> -->
            <!-- <li class="nav-item text-center navigation_item product_navigation_item">
              <div class="product_item">
                <span> <img src="../public/images/navbar-icon/product.png" alt="" class="navigation_image"> </span>
                <a class="nav-link  navigation_item_text" aria-current="page" href="#">Product</a>
              </div>

              <ul class="product_submenu dropdown-menu shadow">
                <li>
                  <a class="dropdown-item" href="#">Category 1</a>
                </li>
                <li><a class="dropdown-item" href="#">Category 2</a></li>
                <li><a class="dropdown-item" href="#">Category 3</a></li>
                <li><a class="dropdown-item" href="#">Category 4</a></li>
                <li><a class="dropdown-item" href="#">Category 5</a></li>
                <li><a class="dropdown-item" href="#">Category 6</a></li>
                <li><a class="dropdown-item" href="#">Category 7</a></li>
              </ul>
            </li> -->

           
            <!-- <li class="nav-item text-center navigation_item">
              <span> <img src="../public/images/navbar-icon/cart.png" alt="" class="navigation_image"> </span>
              <a class="nav-link  navigation_item_text" aria-current="page" href="#">Cart</a>
            </li> -->

            <!-- <li class="nav-item text-center navigation_item product_navigation_item">
              <div class="product_item">
                <span> <img src="../public/images/navbar-icon/profile.png" alt="" class="navigation_image"> </span>
                <a class="nav-link  navigation_item_text" aria-current="page" href="#">Login/Register</a>
              </div>

              <ul class="product_submenu dropdown-menu shadow">
                <li>
                  <a class="dropdown-item" href="#">Total Orders</a>
                </li>
                <li><a class="dropdown-item" href="#">Deliverd Order</a></li>
                <li><a class="dropdown-item" href="#">Panding Order</a></li>
                <li><a class="dropdown-item" href="#">Cancel Order</a></li>
              </ul>
            </li> -->
          </ul>
        </div>
      </div>
    </nav>
  </header>

  <router-view/>

</template>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
