<template>
    <div class="row">
        <div class="col-md-2 mt-5 d-none">
            <div class="col-md-7 d-flex gap-5" style="margin-left:25px;">
                <div class="d-flex flex-column gap-2 site_poster_content">
                    <img src="../assets/files/temp_2.svg" alt="" class="thumbnails" style="border: 2px solid black;">
                    <img src="../assets/files/temp_2.svg" alt="" class="thumbnails" style="border: 2px solid black;">
                    <img src="../assets/files/temp_2.svg" alt="" class="thumbnails" style="border: 2px solid black;">
                    <img src="../assets/files/temp_2.svg" alt="" class="thumbnails" style="border: 2px solid black;">
                </div>

            </div>
        </div>
        <div class="col-md-6 mt-5">
            <!-- <div class="svgElement">
                <inline-svg id="ratingIcon" :src="require('../assets/files/star_1.svg')" @loaded="onSvgLoaded"
                    class="RatingIconw" ref="inlineSvgRef" />

                <div id="celestial-map" class="starLink"></div>

            </div> -->

            <div class="svgElement">
            <inline-svg id="ratingIcon" :src="require('../assets/files/star_1.svg')" @loaded="onSvgLoaded"
                class="RatingIconw" ref="inlineSvgRef" />
            <div class="secondaryElement">

                <div class="mapElement">
                <div id="celestial-map"></div>
                </div>

            </div>

            </div>

        </div>

        <div class="col-md-4 mt-5" id="tabElements">
            <div class="py-4 px-4 bg-light shadow-sm input_content">
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <!-- <div id="celestial-map"></div> -->

                    </div>
                    <div class="save_text"><a href="">Save</a></div>
                </div>


                <!-- tab button -->
                <div class="tab_content">
                    <ul class="nav nav-pills" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active  shadow-lg confi_button" id="design-tab" data-bs-toggle="tab"
                                data-bs-target="#design" type="button" role="tab" aria-controls="design"
                                aria-selected="true" @click="hideStarMapFunction">Design</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link shadow-lg confi_button" id="moment-tab" data-bs-toggle="tab"
                                data-bs-target="#moment" type="button" role="tab" aria-controls="moment"
                                aria-selected="false" @click="hideStarMapFunction">Moment</button>
                        </li>
                        <li class="nav-item " role="presentation">
                            <button class="nav-link shadow-lg confi_button" id="customize-tab" data-bs-toggle="tab"
                                data-bs-target="#customize" type="button" role="tab" aria-controls="customize"
                                aria-selected="false" @click="hideStarMapFunction">Customize</button>
                        </li>
                        <li class="nav-item " role="presentation">
                            <button class="nav-link shadow-lg confi_button" id="size-tab" data-bs-toggle="tab"
                                data-bs-target="#size" type="button" role="tab" aria-controls="size" aria-selected="false"
                                @click="hideStarMapFunction">Size</button>
                        </li>
                       
                    </ul>



                    <div class="tab-content" id="myTabContent">


                        <!-- deign tab content -->
                        <div class="tab-pane fade show active" style="background-color: transparent !important;" id="design"
                            role="tabpanel" aria-labelledby="design-tab">
                            <div class="mt-4 px-2">
                                <p class="select_your_design">Select your favorite design:</p>

                                <!-- accordion -->


                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingOne">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#birthday" aria-expanded="false"
                                                aria-controls="birthday" id="confi_accordion_button">
                                                <img src="../assets/images/confi-image/birthday.png"
                                                    class="accordion_confi_image" alt=""> Birthday
                                            </button>
                                        </h2>
                                        <div id="birthday" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingTwo">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#loved" aria-expanded="false"
                                                aria-controls="loved">
                                                <img src="../assets/images/confi-image/heart.png"
                                                    class="accordion_confi_image" alt=""> For Loved One
                                            </button>
                                        </h2>
                                        <div id="loved" class="accordion-collapse collapse" aria-labelledby="loved"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#parents" aria-expanded="false"
                                                aria-controls="parents">
                                                <img src="../assets/images/confi-image/user-confi.png"
                                                    class="accordion_confi_image" alt=""> For Parents
                                            </button>
                                        </h2>
                                        <div id="parents" class="accordion-collapse collapse" aria-labelledby="parents"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#wedding" aria-expanded="false"
                                                aria-controls="wedding">
                                                <img src="../assets/images/confi-image/right-confi.png"
                                                    class="accordion_confi_image" alt=""> Anniversary &
                                                Wedding
                                            </button>
                                        </h2>
                                        <div id="wedding" class="accordion-collapse collapse" aria-labelledby="wedding"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#family" aria-expanded="false"
                                                aria-controls="family">
                                                <img src="../assets/images/confi-image/users-confi.png"
                                                    class="accordion_confi_image" alt=""> Family
                                            </button>
                                        </h2>
                                        <div id="family" class="accordion-collapse collapse" aria-labelledby="family"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <!-- end  -->






                        <!-- moment tab content -->
                        <div class="tab-pane fade" id="moment" role="tabpanel" aria-labelledby="moment-tab">
                            <div class="mt-4 px-2">
                                <p class="select_your_design">Select your favorite design:</p>

                                <!-- accordion -->


                                <div class="accordion accordion-flush" id="accordionFlushExample">



                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingOne">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#birthday" aria-expanded="false"
                                                aria-controls="birthday" id="confi_accordion_button">
                                                Upload Your Photo
                                            </button>
                                        </h2>
                                        <div id="birthday" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">

                                                    <div class="col-md-4" v-for="(imageId, index) in arryImage"
                                                        :key="index">
                                                        <!-- upload photo {{ index + 1 }} -->
                                                        <div class="col">
                                                            <div class="file-upload">
                                                                <label :for="imageId" class="file-label">
                                                                    <span class="icon">
                                                                        <img src="../assets/images/add_photo.png"
                                                                            class="add_photo_image" alt="">
                                                                    </span>
                                                                </label>
                                                                <input type="file" :id="imageId" class="file-input"
                                                                    accept="image/*"
                                                                    @change="onFileChange(imageId, index, $event)" />
                                                            </div>
                                                            <p class="add_image_title">Image {{ index + 1 }}</p>
                                                            <span :id="'btn-' + imageId + index"
                                                                @click="resetImage(imageId, index)"
                                                                class="ImgRemoveBtn">Reset Image</span>
                                                        </div>
                                                    </div>






                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingTwo">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#loved" aria-expanded="false"
                                                aria-controls="loved">
                                                Moment 1 (Starmap)
                                            </button>
                                        </h2>
                                        <div id="loved" class="accordion-collapse collapse show" aria-labelledby="loved"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <div class="col-md-12">
                                                        <div class="custom-select2">
                                                            <select class="location_select form-select"
                                                                data-live-search="true" v-model="starMap.location">
                                                                <option class="select_title" selected disabled>Location
                                                                </option>
                                                                <option v-if="starMap.location === 'dhaka'" selected
                                                                    value="dhaka">Dhaka, Bangladesh</option>
                                                                <option v-if="starMap.location === 'delhi'" selected
                                                                    value="delhi">Delhi, India</option>
                                                                <option v-if="starMap.location === 'mohammadpur'" selected
                                                                    value="mohammadpur">Mohammapur, Dhaka
                                                                </option>
                                                            </select>



                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="custom-select3 mt-4" id="">
                                                            <input type="date" id="datepicker" v-model="starMap.date"
                                                                class="form-control datepicker moment_date_picker1"
                                                                placeholder="Date">
                                                        </div>
                                                    </div>


                                                    <!-- <div class="col-md-12">
                                                        <div class="custom-select_time mt-4 position-relative" id="">
                                                            <div><img src="../assets/images/time.png" alt=""
                                                                    class="time_icon"></div>
                                                            <input type="time"  class="form-control  current_time"
                                                                placeholder="Time">
                                                        </div>
                                                    </div> -->


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#parents" aria-expanded="false"
                                                aria-controls="parents">
                                                Moment 2 (Street Map)
                                            </button>
                                        </h2>
                                        <div id="parents" class="accordion-collapse collapse" aria-labelledby="parents"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <div class="col-md-12">
                                                        <div class="custom-select2">
                                                            <select class="location_select form-select"
                                                                data-live-search="true" v-model="streetMap.location">
                                                                <option class="select_title" selected disabled>Location
                                                                </option>
                                                                <option v-if="starMap.location === 'dhaka'" selected
                                                                    value="dhaka">Dhaka, Bangladesh</option>
                                                                <option v-if="starMap.location === 'delhi'" selected
                                                                    value="delhi">Delhi, India</option>
                                                                <option v-if="starMap.location === 'mohammadpur'" selected
                                                                    value="mohammadpur">Mohammapur, Dhaka
                                                                </option>
                                                            </select>



                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                </div>


                            </div>
                        </div>

                        <!-- end -->




                        <!-- customize tab content -->
                        <div class="tab-pane fade" id="customize" role="tabpanel" aria-labelledby="customize-tab">
                            <div class="mt-4 px-2">
                                <p class="select_your_design">Select your favorite design:</p>

                                <!-- accordion -->


                                <div class="accordion accordion-flush" id="accordionFlushExample">

                                    <div class="mb-5">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="d-flex">
                                                    <div class="d-flex">
                                                        <label for="" class="m-2">Location</label>
                                                        <input type="number" class="form-control m-1" placeholder="Latitude"
                                                            v-model="this.starmapApi.center.lat">
                                                        <input type="number" class="form-control m-1"
                                                            placeholder="Longitude" v-model="this.starmapApi.center.long">
                                                    </div>

                                                    <div class="d-flex">
                                                        <label for="" class="m-2">Date/Time</label>
                                                        <input type="date" class="form-control m-1" placeholder="Latitude">
                                                        <input type="time" class="form-control m-1" placeholder="Latitude">

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <hr>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row d-flex">
                                                    <div class="mx-1">
                                                        <span><b>Stars</b> <input type="checkbox"
                                                                v-model="this.starmapApi.stars.show"></span>
                                                        <span>down to magnitude <input type="number"
                                                                v-model="this.starmapApi.stars.limit" class="cinput"
                                                                :disabled="!this.starmapApi.stars.show"></span>
                                                        <span>with spectral colors <input type="checkbox"
                                                                v-model="this.starmapApi.stars.colors"
                                                                :disabled="!this.starmapApi.stars.show"></span>
                                                        <span>or default color <input type="color"
                                                                v-model="this.starmapApi.stars.style.fill" class="cinput"
                                                                :disabled="!this.starmapApi.stars.show"></span>
                                                    </div>
                                                </div>

                                                <div class="row d-flex">
                                                    <div class="mx-1">
                                                        <span>Stellar disk size: base
                                                            <input type="number" v-model="this.starmapApi.stars.size"
                                                                class="cinput" :disabled="!this.starmapApi.stars.show">
                                                        </span>
                                                        <span>
                                                            * e ^ (exponent
                                                            <input type="number" v-model="this.starmapApi.stars.exponent"
                                                                class="cinput" :disabled="!this.starmapApi.stars.show">
                                                            * (magnitude + 2)) [* adaptation]
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <hr>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row d-flex">
                                                    <div class="mx-1">
                                                        <span><b>DSOs</b> <input type="checkbox"
                                                                v-model="this.starmapApi.dsos.show"></span>
                                                        <span>down to mag <input type="number"
                                                                v-model="this.starmapApi.dsos.limit"
                                                                :disabled="!this.starmapApi.dsos.show" class="cinput"
                                                                id=""></span>
                                                        <span>with symbol colors <input type="checkbox"
                                                                v-model="this.starmapApi.dsos.colors"
                                                                :disabled="!this.starmapApi.dsos.show"> </span>
                                                        <span>or default color <input type="color" class="cinput"
                                                                v-model="this.starmapApi.dsos.style.fill"
                                                                :disabled="!this.starmapApi.dsos.show"> </span>
                                                        <span>DSO symbol size: (base <input type="number"
                                                                v-model="this.starmapApi.dsos.size" class="cinput"
                                                                :disabled="!this.starmapApi.dsos.show"></span>
                                                        <span>* 2 [* adaptation] - magnitude) ^ exponent <input
                                                                type="number" v-model="this.starmapApi.dsos.exponent"
                                                                class="cinput"
                                                                :disabled="!this.starmapApi.dsos.show"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row d-flex">
                                                    <div class="mx-1">
                                                        <span><b>Constellations </b> <input type="checkbox"
                                                                v-model="this.starmapApi.constellations.names">
                                                        </span>
                                                        <span>lines <input type="checkbox" class="cinput"
                                                                v-model="this.starmapApi.constellations.lines"
                                                                :disabled="!this.starmapApi.constellations.names"></span>
                                                        <span>color <input type="color" class="cinput"
                                                                v-model="this.starmapApi.constellations.lineStyle.stroke"
                                                                :disabled="!this.starmapApi.constellations.names"></span>
                                                        <span>boundaries <input type="checkbox" class="cinput"
                                                                v-model="this.starmapApi.constellations.bounds"
                                                                :disabled="!this.starmapApi.constellations.names"></span>
                                                        <span>color <input type="color" class="cinput"
                                                                v-model="this.starmapApi.constellations.boundStyle.stroke"
                                                                :disabled="!this.starmapApi.constellations.names"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr>


                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row d-flex">
                                                    <div class="mx-1">
                                                        <span><b>Lines </b> </span>
                                                        <span>Graticule <input type="checkbox"
                                                                v-model="this.starmapApi.lines.graticule.show"></span>
                                                        <span>Equator <input type="checkbox"
                                                                v-model="this.starmapApi.lines.equatorial.show"></span>
                                                        <span>Ecliptic <input type="checkbox"
                                                                v-model="this.starmapApi.lines.ecliptic.show"></span>
                                                        <span>Galactic plane <input type="checkbox"
                                                                v-model="this.starmapApi.lines.galactic.show"></span>
                                                        <span>Supergalactic plane <input type="checkbox"
                                                                v-model="this.starmapApi.lines.supergalactic.show"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row d-flex">
                                                    <div class="mx-1">
                                                        <span><b>Other </b> </span>
                                                        <span>Milky Way <input type="checkbox"
                                                                v-model="this.starmapApi.mw.show"></span>
                                                        <span>color <input type="color" class="cinput"
                                                                v-model="this.starmapApi.mw.style.fill"
                                                                :disabled="!this.starmapApi.mw.show"></span>
                                                        <span>opacity <input type="number" class="cinput"
                                                                v-model="this.starmapApi.mw.style.opacity"
                                                                :disabled="!this.starmapApi.mw.show"> </span>
                                                        <br>
                                                        <span>Background Color <input type="color" class="cinput"
                                                                v-model="this.starmapApi.background.fill">
                                                        </span>
                                                        <span>opacity <input type="number" class="cinput"
                                                                v-model="this.starmapApi.background.opacity"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingOne">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#birthday" aria-expanded="false"
                                                aria-controls="birthday" id="confi_accordion_button">
                                                Text
                                            </button>
                                        </h2>
                                        <div id="birthday" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-lect  row">
                                                    <div v-for="(inputId, index) in arryText" :key="index"
                                                        class="form-group col-md-12">
                                                        <label :for="inputId">Field {{ index + 1 }}:</label>
                                                        <input type="text" class="form-control" :id="inputId"
                                                            :placeholder="'Enter value for ' + inputId"
                                                            v-model="svg[inputId]" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingOne">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#birthday" aria-expanded="false"
                                                aria-controls="birthday" id="confi_accordion_button">
                                                Color
                                            </button>
                                        </h2>
                                        <div id="birthday" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-lect  row">
                                                    <div v-for="(pathId, index) in arryColor" :key="index"
                                                        class="form-group col-md-4 d-flex mb-3">
                                                        <label :for="pathId">Color {{ index + 1 }}:</label>
                                                        <!-- Replace text input with color input -->
                                                        <input type="color" class="form-control" :id="pathId"
                                                            @input="onColorChange(pathId, $event)" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingTwo">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#loved" aria-expanded="false"
                                                aria-controls="loved">
                                                Font Style
                                            </button>
                                        </h2>
                                        <div id="loved" class="accordion-collapse collapse show" aria-labelledby="loved"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center">
                                                    <!-- body -->
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <div class="dropdown">
                                                                <input type="button" value="Main Text"
                                                                    class="frame_button shadow active_frame_button">
                                                                <!--font style  -->
                                                                <ul class="list-group font_style_dropdown mt-2"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <li class="dropdown-item"><a href="">Font 1</a></li>
                                                                    <li class="dropdown-item"><a href="">Fira COde</a>
                                                                    </li>
                                                                    <li class="dropdown-item"><a href="">Bardana</a>
                                                                    </li>
                                                                    <li class="dropdown-item"><a href="">Latin</a></li>
                                                                    <li class="dropdown-item"><a href="">Jost</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <input type="button" href="#" value="2nd Text"
                                                                class="frame_button shadow">
                                                            <ul class="list-group font_style_dropdown mt-2"
                                                                aria-labelledby="dropdownMenuButton1">
                                                                <li class="dropdown-item"><a href="">Font
                                                                        1</a></li>
                                                                <li class="dropdown-item"><a href="">Fira
                                                                        COde</a></li>
                                                                <li class="dropdown-item"><a href="">Bardana</a></li>
                                                                <li class="dropdown-item"><a href="">Latin</a></li>
                                                                <li class="dropdown-item"><a href="">Jost</a></li>
                                                            </ul>
                                                        </div>


                                                        <div class="col-md-4">
                                                            <input type="button" href="#" value="3rd Text"
                                                                class="frame_button shadow">
                                                            <ul class="list-group font_style_dropdown mt-2"
                                                                aria-labelledby="dropdownMenuButton1">
                                                                <li class="dropdown-item"><a href="">Font
                                                                        1</a></li>
                                                                <li class="dropdown-item"><a href="">Fira
                                                                        COde</a></li>
                                                                <li class="dropdown-item"><a href="">Bardana</a></li>
                                                                <li class="dropdown-item"><a href="">Latin</a></li>
                                                                <li class="dropdown-item"><a href="">Jost</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- end  -->



                        <!-- size tab content -->

                        <div class="tab-pane fade" id="size" role="tabpanel" aria-labelledby="size-tab">
                            <div class="mt-4 px-2">
                                <p class="select_your_design">Select your favorite design:</p>


                                <div class="mt-4">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center gap-2">
                                            <div><input type="radio" name="inch&price" class="form-check-input"
                                                    id="inch&price"> <span class="inch_text">12 * 16 inch</span></div>
                                        </div>
                                        <div>
                                            <div> <span class="price"><del>৳1,595</del></span> <span
                                                    class="selling_price">৳1,595</span></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center gap-2">
                                            <div><input type="radio" name="inch&price" class="form-check-input"
                                                    id="inch&price"> <span class="inch_text">18 * 24 inch</span></div>
                                        </div>
                                        <div>
                                            <div> <span class="price"><del>৳1,595</del></span> <span
                                                    class="selling_price">৳1,595</span></div>
                                        </div>
                                    </div>
                                </div>


                                <div class="mt-4">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center gap-2">
                                            <div><input type="radio" name="inch&price" class="form-check-input"
                                                    id="inch&price"> <span class="inch_text">24 * 32 inch</span></div>
                                        </div>
                                        <div>
                                            <div> <span class="price"><del>৳1,595</del></span> <span
                                                    class="selling_price">৳1,595</span></div>
                                    </div>
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center gap-2">
                                            <div>
                                                <input type="radio" name="inch&price"
                                                    class="form-check-input custom_inch_radio" id="inch&price">
                                                <span class="custom">Custom</span>
                                                <span><input type="text" name="" id="" class="custom_inch_value1"
                                                        placeholder="in"></span>
                                                <span>*</span>
                                                <span><input type="text" name="" id="" class="custom_inch_value2"
                                                        placeholder="in"></span>

                                            </div>
                                        </div>
                                        <div>
                                            <div> <span class="price"><del>৳1,595</del></span> <span
                                                    class="selling_price">৳1,595</span></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-4 custom_border_for_size">

                                </div>


                                <!-- chose frame -->

                                <div class="mt-4">
                                    <p class="choose_frame_text">Choose Frame</p>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <input type="button" href="#" value="Standart"
                                                class="frame_button shadow active_frame_button">
                                        </div>

                                        <div class="col-md-3">
                                            <input type="button" href="#" value="Metal" class="frame_button shadow">
                                        </div>


                                        <div class="col-md-3">
                                            <input type="button" href="#" value="Wood" class="frame_button shadow">
                                        </div>



                                    </div>
                                </div>





                            </div>
                        </div>

                        <!-- end size tab content -->



                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
    name: "RatingIcon",
    props: ['formData'],
    components: {
        InlineSvg,
    },
    data() {
        return {
            starmapApi: {


                center: {
                    long: 0, lat: 0, orient: 0
                },
                stars: {
                    show: false,    // Show stars
                    limit: 6,      // Show only stars brighter than limit magnitude
                    colors: true,  // Show stars in spectral colors, if not use default color
                    style: {

                        fill: "#ffffff",
                        opacity: 1

                    },

                    size: 7,       // Maximum size (radius) of star circle in pixels
                    exponent: -0.28, // Scale exponent for star size, larger = more linear
                    data: 'stars.6.json' // Data source for stellar data, 

                },
                dsos: {
                    show: true,    // Show Deep Space Objects 
                    limit: 6,      // Show only DSOs brighter than limit magnitude
                    colors: true,  // // Show DSOs in symbol colors if true, use style setting below if false
                    style: {
                        fill: "#cccccc",
                        stroke: "#cccccc", width: 2, opacity: 1
                    }, // Default style for dsos

                    size: null,    // Optional seperate scale size for DSOs, null = stars.size
                    exponent: 1.4, // Scale exponent for DSO size, larger = more non-linear
                    data: 'dsos.bright.json', // Data source for DSOs, 

                },
                constellations: {
                    names: true,      // Show constellation names 
                    namesType: "iau", // Type of name Latin (iau, default), 3 letter designation (desig) or other language (see list below)
                    nameStyle: {
                        fill: "#cccc99", align: "center", baseline: "middle",
                        font: ["14px Helvetica, Arial, sans-serif",  // Style for constellations
                            "12px Helvetica, Arial, sans-serif",  // Different fonts for diff.
                            "11px Helvetica, Arial, sans-serif"]
                    },// ranked constellations

                    lines: true,   // Show constellation lines, style below
                    lineStyle: {
                        stroke: "#cccccc",
                        width: 1, opacity: 0.6
                    },
                    bounds: false, // Show constellation boundaries, style below
                    boundStyle: { stroke: "#cccc00", width: 0.5, opacity: 0.8, dash: [2, 4] }
                },
                mw: {
                    show: false,     // Show Milky Way as filled multi-polygon outlines 
                    style: { fill: "#ffffff", opacity: 0.15 }  // Style for MW layers
                },
                lines: {  // Display & styles for graticule & some planes
                    graticule: {
                        show: true, stroke: "#cccccc", width: 0.6, opacity: 0.8,
                        // grid values: "outline", "center", or [lat,...] specific position
                        lon: { pos: [""], fill: "#eee", font: "10px Helvetica, Arial, sans-serif" },
                        // grid values: "outline", "center", or [lon,...] specific position
                        lat: { pos: [""], fill: "#eee", font: "10px Helvetica, Arial, sans-serif" }
                    },
                    equatorial: { show: true, stroke: "#aaaaaa", width: 1.3, opacity: 0.7 },
                    ecliptic: { show: false, stroke: "#66cc66", width: 1.3, opacity: 0.7 },
                    galactic: { show: false, stroke: "#cc6666", width: 1.3, opacity: 0.7 },
                    supergalactic: { show: false, stroke: "#cc66cc", width: 1.3, opacity: 0.7 }
                },
                background: {        // Background style
                    fill: "#000000",   // Area fill
                    opacity: 1,
                    stroke: "#000000", // Outline
                    width: 1.5
                },
            },
            apiKey: 'AIzaSyAOVYRIgupAurZup5y1PRh8Ismb1A3lLao',
            zoom: 10,
            iframzeSrc: '',
            streetMap: {
                latitude: "",
                longitude: "",
                location: ""

            },
            starMap: {
                latitude: "",
                longitude: "",
                date: "",
                location: ""

            },

            activeTab: 'text',
            svg: {

            },
            arryText: [
                // 'tspan75',
                'text22',
                'text24',
                'text28'
            ],
            arryStarMap: [
                'image16'
            ],
            arrySreetMap: [
                // 'g299'
            ],
            arryImage: [
                // 'image297',
                // 'image307',
                // 'image16',

            ],
            arryColor: [
                // 'path295',
                // 'path285',
                // 'path305',

                // 'path283',
                // 'path281',
                // 'rect9'

            ],
            url: null,
            streerMap_img_url: null,
            starpMap_img_url: null,

            castStarMapID: 'g309',
        };
    },
    
   
    mounted() {

        // console.log('Received formData from route params:', JSON.parse(this.$route.params.formData));

        const formData = JSON.parse(this.$route.params.formData);
        this.starMap.date = formData.date
        this.starMap.location = formData.location
        this.streetMap.location = formData.location

        if (formData.location === 'dhaka') {

            this.streetMap.latitude = parseFloat("23.8041");
            this.streetMap.longitude = parseFloat("90.4152");

            this.starMap.latitude = parseFloat("23.8041");
            this.starMap.longitude = parseFloat("90.4152");

        } else if (formData.location === 'delhi') {

            this.streetMap.latitude = parseFloat("28.7041");
            this.streetMap.longitude = parseFloat("77.1025");

            this.starMap.latitude = parseFloat("28.7041");
            this.starMap.longitude = parseFloat("77.1025");

        } else if (formData.location === 'mohammadpur') {

            this.streetMap.latitude = parseFloat("23.7662");
            this.streetMap.longitude = parseFloat("90.3589");

            this.starMap.latitude = parseFloat("23.7662");
            this.starMap.longitude = parseFloat("90.3589");

        }

        console.log(this.streetMap, this.starMap);

        // this.generateStarMapClientAPI();
        // this.hideStarMapFunction();
        // this.customFunction();
        this.customFunction();

    },
   
    methods: {
        customFunction() {


            var config = {
                disableAnimations: false,
                form: false,
                formFields: {
                    download: false
                },
                datapath: "/data/",

                // orientationfixed: true, 
                advanced: false,

                // center : [this.starmapApi.center.lang , this.starmapApi.center.lat, this.starmapApi.center.orient],
                stars: this.starmapApi.stars,
                dsos: this.starmapApi.dsos,
                constellations: this.starmapApi.constellations,
                mw: this.starmapApi.mw,
                lines: this.starmapApi.lines,
                background: this.starmapApi.background,

            }

            // Display map with the configuration above or any subset thereof
            Celestial.display(config);

        },
        updateStarMap() {
            // This method will be called whenever any watched property changes
            this.customFunction();
        },
        hideStarMapFunction() {
            document.getElementById('celestial-form').style.display = 'none'

        },
        showStarMapFunction() {
            document.getElementById('celestial-form').style.display = 'block'
        },
        generateStarMapClientAPI() {
            var config = {
                disableAnimations: false,
                form: false,
                formFields: {
                    download: true
                },
                datapath: "/data/",
                stars: {
                    propername: true
                },
            };
            Celestial.display(config);

            // console.log(Celestial);



        },

        getDiv(id) {
            const el = document.getElementById(id);
            return el;
        },
        getDivHidden(id) {
            this.getDiv(id).style.display = 'none';
        },
        getDivShow(id) {
            this.getDiv(id).style.display = 'inline';
        },

        onFileChange(imageId, index, e) {

            let restoreImageBtn = document.getElementById('btn-' + imageId + index)
            restoreImageBtn.style.display = 'block'

            const file = e.target.files[0];
            this.url = URL.createObjectURL(file);

            let previewImage = document.getElementById(imageId);
            previewImage.setAttribute('xlink:href', this.url);

            let isDragging = false;

            // Mouse down event
            previewImage.addEventListener('mousedown', (downEvent) => {
                isDragging = true;

                // Get the initial mouse position
                const initialX = downEvent.clientX;
                const initialY = downEvent.clientY;

                // Get the initial image position
                const initialImageX = parseFloat(previewImage.getAttribute('x')) || 0;
                const initialImageY = parseFloat(previewImage.getAttribute('y')) || 0;

                // Mouse move event
                const onMouseMove = (moveEvent) => {
                    if (isDragging) {
                        // Calculate the new image position based on mouse movements
                        const deltaX = moveEvent.clientX - initialX;
                        const deltaY = moveEvent.clientY - initialY;

                        const newImageX = initialImageX + deltaX;
                        const newImageY = initialImageY + deltaY;

                        // Set the new image position
                        previewImage.setAttribute('x', newImageX);
                        previewImage.setAttribute('y', newImageY);
                    }
                };

                // Mouse up event
                const onMouseUp = () => {
                    isDragging = false;

                    // Remove the event listeners once dragging is done
                    document.removeEventListener('mousemove', onMouseMove);
                    document.removeEventListener('mouseup', onMouseUp);
                };

                // Add event listeners for mouse move and mouse up
                document.addEventListener('mousemove', onMouseMove);
                document.addEventListener('mouseup', onMouseUp);
            });
        },
        onColorChange(pathId, event) {
            // Get the selected color value from the event
            const selectedColor = event.target.value;

            // Update the corresponding path element with the selected color
            const pathElement = document.getElementById(pathId);
            if (pathElement) {
                pathElement.style.fill = selectedColor;
            }
        },
        onSvgLoaded() {

            let svgComponent = document.getElementById('ratingIcon');
            svgComponent.style.width = '60%';
            svgComponent.style.margin = '60 0 60 0';
            svgComponent.style.border = '3px solid black';

            // this.generateStreetMap()
        },
        handleSubmit() {
            event.preventDefault();
        },
        setActiveTab(tab) {
            this.activeTab = tab;
        },

        resetImage(imageId, index) {

            let restoreImageBtn = document.getElementById('btn-' + imageId + index)
            restoreImageBtn.style.display = 'none'
            this.url = null; // You can set the default image URL here if needed

            const fileInput = document.getElementById(imageId);
            if (fileInput) {
                fileInput.value = null;
            }

            // Reset the preview image to the default image or remove it
            let previewImage = document.getElementById(imageId);
            if (previewImage) {
                previewImage.setAttribute('xlink:href', ''); // Set to the default image URL if needed
            }
        },

    },
    watch: {
        svg: {
            handler(newValues) {
                // Iterate over the properties of svg and update corresponding elements in the SVG
                for (const [id, value] of Object.entries(newValues)) {
                    const svgElement = this.$refs.inlineSvgRef.$el.querySelector(`#${id}`);
                    if (svgElement) {
                        svgElement.innerHTML = value;
                    }
                }
            },
            deep: true, // Enable deep watching to watch nested properties
        },
        'starmapApi.stars.show': 'updateStarMap',
        'starmapApi.stars.limit': 'updateStarMap',
        'starmapApi.stars.colors': 'updateStarMap',
        'starmapApi.stars.style.fill': 'updateStarMap',
        'starmapApi.stars.size': 'updateStarMap',
        'starmapApi.stars.exponent': 'updateStarMap',

        'starmapApi.dsos.show': 'updateStarMap',
        'starmapApi.dsos.exponent': 'updateStarMap',
        'starmapApi.dsos.size': 'updateStarMap',
        'starmapApi.dsos.style.fill': 'updateStarMap',
        'starmapApi.dsos.colors': 'updateStarMap',
        'starmapApi.dsos.limit': 'updateStarMap',

        'starmapApi.constellations.boundStyle.stroke': 'updateStarMap',
        'starmapApi.constellations.bounds': 'updateStarMap',
        'starmapApi.constellations.lineStyle.stroke': 'updateStarMap',
        'starmapApi.constellations.lines': 'updateStarMap',
        'starmapApi.constellations.names': 'updateStarMap',


        'starmapApi.lines.supergalactic.show': 'updateStarMap',
        'starmapApi.lines.galactic.show': 'updateStarMap',
        'starmapApi.lines.ecliptic.show': 'updateStarMap',
        'starmapApi.lines.equatorial.show': 'updateStarMap',
        'starmapApi.lines.graticule.show': 'updateStarMap',

        'starmapApi.mw.style.opacity': 'updateStarMap',
        'starmapApi.mw.style.fill': 'updateStarMap',
        'starmapApi.mw.show': 'updateStarMap',
        'starmapApi.background.opacity': 'updateStarMap',
        'starmapApi.background.fill': 'updateStarMap',


    },

};
</script>

<style scoped>
.ImgRemoveBtn {
    display: none;
    cursor: pointer;
    color: red;

}

.svgElement {
    position: relative;
    z-index: -1;
}

.starLink {
    position: absolute;
    content: '';
    left: 145px;
    top: 110px;
    z-index: 999;
    width: 260px !important;
    height: 260px !important;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.starlink canvas {
    width: 260px !important;
    height: 260px !important;
    overflow: hidden !important;
}




.ImgRemoveBtn {
    display: none;
    cursor: pointer;
    color: red;

  }

  .svgElement {
    position: relative;
    z-index: -1;
  }

  .secondaryElement {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
  }

  .mapElement {
    position: absolute;
    content: '';
    left: 50%;
    top: 200px;
    transform: translateX(-50%);
    z-index: 99;

    width: 45%;
    height: 5;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #celestial-map {
    z-index: 999 !important;
  }

  #celestial-form {
    position: absolute !important;
    content: '';
    right: 0;
    top: 0;
    z-index: 999;

  }
  
  </style>