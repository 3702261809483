<template>


    <main class="py-5">
        <section class="configurator1">
            <div class="container">
                <h1 class="poster_section_title mb-4">Template & Moment</h1>

                <div class="row py-4 d-flex justify-content-center">
                    <!-- <div class="col-md-7 d-flex gap-5 mb-4">
                        <div class="d-flex flex-column gap-2 site_poster_content">
                            <img src="../assets/images/poster_images2/poster7.png" alt="" class="thumbnails">
                            <img src="../assets/images/poster_images2/poster7.png" alt="" class="thumbnails">
                            <img src="../assets/images/poster_images2/poster7.png" alt="" class="thumbnails">
                            <img src="../assets/images/poster_images2/poster7.png" alt="" class="thumbnails">
                        </div>

                        <div class="main_image_content">
                            <img src="../assets/images/poster_images2/poster7.png" alt="" class="output_image" id="output">
                        </div>
                    </div> -->

                    <div class="col-md-6 ">
                        <div class="bg-dark py-4 px-4 input_content">
                            <h3 class="configurator_title">Welcome To Our Configurator</h3>
                            <p class="configurator_subtitle">Let’s start customizing the poster by answering these
                                questions</p>
                           <form  @submit.prevent="submitForm">
                                <div class="d-flex flex-column">
                                    <div class="custom-select">
                                        <select v-model="formData.template" required>
                                            <option class="select_title" selected disabled>Who Are You Designing For ?</option>
                                            <!-- <option value="template1">Template 1</option> -->
                                            <option value="template2">Star Map</option>
                                            <option value="template3">Other Template</option>
                                        </select>
                                    </div>

                                    <div class="custom-select2 mt-4">
                                        <select v-model="formData.location" required>
                                            <option class="select_title" selected disabled>Location</option>
                                            <option value="dhaka">Dhaka, Bangladesh</option>
                                            <option value="delhi">Delhi, India</option>
                                            <option value="mohammadpur">Mohammapur, Dhaka</option>
                                            
                                        </select>
                                    </div>
                                    <div class="custom-select3 mt-4">
                                     <input type="date" name="date" v-model="formData.date" class="form-control" required>
                                    </div>
                                    <div class="next_process_content">
                                        <!-- <a href="./configurator-2" class="next_process">Next Process</a> -->
                                        <button class="next_process" type="submit">Next Process</button>
                                    </div>
                                
                                </div>
                           </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>

</template>


<script>
    export default {
        name: 'Config1',
        components: {

        },
        data(){
            return {
                formData: {
                    date: '',
                    location: '',
                    template: '',
                }
            }
        },
        methods: {
            submitForm() {
                // console.log('Form submitted with data:', this.formData);
                if(this.formData.template === "template1")
                {
                    console.log('T-1');
                    this.$router.push({ name: 'Template1', params: { formData: JSON.stringify(this.formData) } });

                }
                else if(this.formData.template === "template2")
                {
                    console.log('T-2');
                    this.$router.push({ name: 'Template2', params: { formData: JSON.stringify(this.formData) } });

                }
                else{
                    this.$router.push({ name: 'Template3', params: { formData: JSON.stringify(this.formData) } });
                }
            }
        },


    }
</script>