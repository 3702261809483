<template>
    <div class="row">
        <div class="col-md-2 mt-5">
            <div class="col-md-7 d-flex gap-5" style="margin-left:25px;">
                <div class="d-flex flex-column gap-2 site_poster_content">
                    <img src="../assets/files/custom-svg-3.svg" alt="" class="thumbnails"
                        style="border: 2px solid black;">
                    <img src="../assets/files/custom-svg-3.svg" alt="" class="thumbnails"
                        style="border: 2px solid black;">
                    <img src="../assets/files/custom-svg-3.svg" alt="" class="thumbnails"
                        style="border: 2px solid black;">
                    <img src="../assets/files/custom-svg-3.svg" alt="" class="thumbnails"
                        style="border: 2px solid black;">
                </div>

            </div>
        </div>
        <div class="col-md-6">
            <inline-svg id="ratingIcon" :src="require('../assets/files/custom-svg-3.svg')" @loaded="onSvgLoaded"
                class="RatingIconw" ref="inlineSvgRef" />
        </div>

        <div class="col-md-4 mt-5">
            <div class="py-4 px-4 bg-light shadow-sm input_content">
                <div class="d-flex align-items-center justify-content-between">
                    <div><img src="../assets/images/confi-image/bulve.png" class="bulve" alt=""></div>
                    <div class="save_text"><a href="">Save</a></div>
                </div>


                <!-- tab button -->
                <div class="tab_content">
                    <ul class="nav nav-pills" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active  shadow-lg confi_button" id="design-tab" data-bs-toggle="tab"
                                data-bs-target="#design" type="button" role="tab" aria-controls="design"
                                aria-selected="true">Design</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link shadow-lg confi_button" id="moment-tab" data-bs-toggle="tab"
                                data-bs-target="#moment" type="button" role="tab" aria-controls="moment"
                                aria-selected="false">Moment</button>
                        </li>
                        <li class="nav-item " role="presentation">
                            <button class="nav-link shadow-lg confi_button" id="customize-tab" data-bs-toggle="tab"
                                data-bs-target="#customize" type="button" role="tab" aria-controls="customize"
                                aria-selected="false">Customize</button>
                        </li>
                        <li class="nav-item " role="presentation">
                            <button class="nav-link shadow-lg confi_button" id="size-tab" data-bs-toggle="tab"
                                data-bs-target="#size" type="button" role="tab" aria-controls="size"
                                aria-selected="false">Size</button>
                        </li>
                    </ul>



                    <div class="tab-content" id="myTabContent">


                        <!-- deign tab content -->
                        <div class="tab-pane fade show active" style="background-color: transparent !important;"
                            id="design" role="tabpanel" aria-labelledby="design-tab">
                            <div class="mt-4 px-2">
                                <p class="select_your_design">Select your favorite design:</p>

                                <!-- accordion -->


                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingOne">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#birthday"
                                                aria-expanded="false" aria-controls="birthday"
                                                id="confi_accordion_button">
                                                <img src="../assets/images/confi-image/birthday.png"
                                                    class="accordion_confi_image" alt=""> Birthday
                                            </button>
                                        </h2>
                                        <div id="birthday" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingTwo">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#loved" aria-expanded="false"
                                                aria-controls="loved">
                                                <img src="../assets/images/confi-image/heart.png"
                                                    class="accordion_confi_image" alt=""> For Loved One
                                            </button>
                                        </h2>
                                        <div id="loved" class="accordion-collapse collapse" aria-labelledby="loved"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#parents"
                                                aria-expanded="false" aria-controls="parents">
                                                <img src="../assets/images/confi-image/user-confi.png"
                                                    class="accordion_confi_image" alt=""> For Parents
                                            </button>
                                        </h2>
                                        <div id="parents" class="accordion-collapse collapse" aria-labelledby="parents"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#wedding"
                                                aria-expanded="false" aria-controls="wedding">
                                                <img src="../assets/images/confi-image/right-confi.png"
                                                    class="accordion_confi_image" alt=""> Anniversary &
                                                Wedding
                                            </button>
                                        </h2>
                                        <div id="wedding" class="accordion-collapse collapse" aria-labelledby="wedding"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#family" aria-expanded="false"
                                                aria-controls="family">
                                                <img src="../assets/images/confi-image/users-confi.png"
                                                    class="accordion_confi_image" alt=""> Family
                                            </button>
                                        </h2>
                                        <div id="family" class="accordion-collapse collapse" aria-labelledby="family"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="">
                                                            <img src="../assets/images/confi-poster-shape.png"
                                                                class="birthday_template" alt="">
                                                            <p class="birthday_template_text">Template Name
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <a href="" style="text-decoration: none;">
                                                            <div class="">
                                                                <img src="../assets/images/confi-poster-shape.png"
                                                                    class="birthday_template" alt="">
                                                                <p class="birthday_template_text">Template
                                                                    Name</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <!-- end  -->






                        <!-- moment tab content -->
                        <div class="tab-pane fade" id="moment" role="tabpanel" aria-labelledby="moment-tab">
                            <div class="mt-4 px-2">
                                <p class="select_your_design">Select your favorite design:</p>

                                <!-- accordion -->


                                <div class="accordion accordion-flush" id="accordionFlushExample">



                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingOne">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#birthday"
                                                aria-expanded="false" aria-controls="birthday"
                                                id="confi_accordion_button">
                                                Upload Your Photo
                                            </button>
                                        </h2>
                                        <div id="birthday" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">

                                                    <div class="col-md-4" v-for="(imageId, index) in arryImage"
                                                        :key="index">
                                                        <!-- upload photo {{ index + 1 }} -->
                                                        <div class="col">
                                                            <div class="file-upload">
                                                                <label :for="imageId" class="file-label">
                                                                    <span class="icon">
                                                                        <img src="../assets/images/add_photo.png"
                                                                            class="add_photo_image" alt="">
                                                                    </span>
                                                                </label>
                                                                <input type="file" :id="imageId" class="file-input"
                                                                    accept="image/*"
                                                                    @change="onFileChange(imageId, index, $event)" />
                                                            </div>
                                                            <p class="add_image_title">Image {{ index + 1 }}</p>
                                                            <span :id="'btn-' + imageId + index"
                                                                @click="resetImage(imageId, index)"
                                                                class="ImgRemoveBtn">Reset Image</span>
                                                        </div>
                                                    </div>






                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingTwo">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#loved" aria-expanded="false"
                                                aria-controls="loved">
                                                Moment 1
                                            </button>
                                        </h2>
                                        <div id="loved" class="accordion-collapse collapse show" aria-labelledby="loved"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <!-- body -->
                                                    <div class="col-md-12">
                                                        <div class="custom-select2">
                                                            <select class="location_select location_select form-select"
                                                                data-live-search="true">
                                                                <option class="select_title" selected disabled>Location
                                                                </option>
                                                                <option value="option2">Lorem Ipsum</option>
                                                                <option value="">Lorem Ipsum</option>
                                                                <option value="option3">Lorem Ipsum</option>
                                                                <option value="">Lorem Ipsum</option>
                                                                <option value="">Lorem Ipsum</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="custom-select3 mt-4" id="">
                                                            <input type="text" id="datepicker"
                                                                class="form-control datepicker moment_date_picker1"
                                                                placeholder="Date">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12 mt-4">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <div>
                                                                <p class="use_default_time_text">Use Default
                                                                    Time</p>
                                                            </div>
                                                            <div>
                                                                <div class="custom-switch">
                                                                    <input type="checkbox" class="custom-control-input"
                                                                        id="customSwitch">
                                                                    <label class="custom-control-label"
                                                                        for="customSwitch"></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="custom-select_time mt-2 position-relative" id="">
                                                            <div><img src="../assets/images/time.png" alt=""
                                                                    class="time_icon"></div>
                                                            <input type="text" cl class="form-control  current_time"
                                                                disabled placeholder="Time">
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#parents"
                                                aria-expanded="false" aria-controls="parents">
                                                Moment 2
                                            </button>
                                        </h2>
                                        <div id="parents" class="accordion-collapse collapse" aria-labelledby="parents"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <!-- body -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                </div>


                            </div>
                        </div>

                        <!-- end -->




                        <!-- customize tab content -->
                        <div class="tab-pane fade" id="customize" role="tabpanel" aria-labelledby="customize-tab">
                            <div class="mt-4 px-2">
                                <p class="select_your_design">Select your favorite design:</p>

                                <!-- accordion -->


                                <div class="accordion accordion-flush" id="accordionFlushExample">



                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingOne">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#birthday"
                                                aria-expanded="false" aria-controls="birthday"
                                                id="confi_accordion_button">
                                                Text
                                            </button>
                                        </h2>
                                        <div id="birthday" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-lect  row">
                                                    <div v-for="(inputId, index) in arryText" :key="index"
                                                        class="form-group col-md-12">
                                                        <label :for="inputId">Field {{ index + 1 }}:</label>
                                                        <input type="text" class="form-control" :id="inputId"
                                                            :placeholder="'Enter value for ' + inputId"
                                                            v-model="svg[inputId]" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingOne">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#birthday"
                                                aria-expanded="false" aria-controls="birthday"
                                                id="confi_accordion_button">
                                                Color
                                            </button>
                                        </h2>
                                        <div id="birthday" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-lect  row">
                                                    <div v-for="(pathId, index) in arryColor" :key="index"
                                                        class="form-group col-md-4 d-flex mb-3">
                                                        <label :for="pathId">Color {{ index + 1 }}:</label>
                                                        <!-- Replace text input with color input -->
                                                        <input type="color" class="form-control" :id="pathId"
                                                            @input="onColorChange(pathId, $event)" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingTwo">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#loved" aria-expanded="false"
                                                aria-controls="loved">
                                                Font Style
                                            </button>
                                        </h2>
                                        <div id="loved" class="accordion-collapse collapse show" aria-labelledby="loved"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center">
                                                    <!-- body -->
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <div class="dropdown">
                                                                <input type="button" value="Main Text"
                                                                    class="frame_button shadow active_frame_button">
                                                                <!--font style  -->
                                                                <ul class="list-group font_style_dropdown mt-2"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <li class="dropdown-item"><a href="">Font 1</a></li>
                                                                    <li class="dropdown-item"><a href="">Fira COde</a>
                                                                    </li>
                                                                    <li class="dropdown-item"><a href="">Bardana</a>
                                                                    </li>
                                                                    <li class="dropdown-item"><a href="">Latin</a></li>
                                                                    <li class="dropdown-item"><a href="">Jost</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <input type="button" href="#" value="2nd Text"
                                                                class="frame_button shadow">
                                                            <ul class="list-group font_style_dropdown mt-2"
                                                                aria-labelledby="dropdownMenuButton1">
                                                                <li class="dropdown-item"><a href="">Font
                                                                        1</a></li>
                                                                <li class="dropdown-item"><a href="">Fira
                                                                        COde</a></li>
                                                                <li class="dropdown-item"><a href="">Bardana</a></li>
                                                                <li class="dropdown-item"><a href="">Latin</a></li>
                                                                <li class="dropdown-item"><a href="">Jost</a></li>
                                                            </ul>
                                                        </div>


                                                        <div class="col-md-4">
                                                            <input type="button" href="#" value="3rd Text"
                                                                class="frame_button shadow">
                                                            <ul class="list-group font_style_dropdown mt-2"
                                                                aria-labelledby="dropdownMenuButton1">
                                                                <li class="dropdown-item"><a href="">Font
                                                                        1</a></li>
                                                                <li class="dropdown-item"><a href="">Fira
                                                                        COde</a></li>
                                                                <li class="dropdown-item"><a href="">Bardana</a></li>
                                                                <li class="dropdown-item"><a href="">Latin</a></li>
                                                                <li class="dropdown-item"><a href="">Jost</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#parents"
                                                aria-expanded="false" aria-controls="parents">
                                                Starmap
                                            </button>
                                        </h2>
                                        <div id="parents" class="accordion-collapse collapse" aria-labelledby="parents"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <!-- body -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#parents"
                                                aria-expanded="false" aria-controls="parents">
                                                Streetmap
                                            </button>
                                        </h2>
                                        <div id="parents" class="accordion-collapse collapse" aria-labelledby="parents"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <!-- body -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item" id="accordion_item">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#parents"
                                                aria-expanded="false" aria-controls="parents">
                                                Color
                                            </button>
                                        </h2>
                                        <div id="parents" class="accordion-collapse collapse" aria-labelledby="parents"
                                            data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body overflow-hidden">
                                                <div class="d-flex align-items-center  row">
                                                    <!-- body -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                </div>


                            </div>
                        </div>


                        <!-- end  -->



                        <!-- size tab content -->

                        <div class="tab-pane fade" id="size" role="tabpanel" aria-labelledby="size-tab">
                            <div class="mt-4 px-2">
                                <p class="select_your_design">Select your favorite design:</p>


                                <div class="mt-4">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center gap-2">
                                            <div><input type="radio" name="inch&price" class="form-check-input"
                                                    id="inch&price"> <span class="inch_text">12 * 16 inch</span></div>
                                        </div>
                                        <div>
                                            <div> <span class="price"><del>৳1,595</del></span> <span
                                                    class="selling_price">৳1,595</span></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center gap-2">
                                            <div><input type="radio" name="inch&price" class="form-check-input"
                                                    id="inch&price"> <span class="inch_text">18 * 24 inch</span></div>
                                        </div>
                                        <div>
                                            <div> <span class="price"><del>৳1,595</del></span> <span
                                                    class="selling_price">৳1,595</span></div>
                                        </div>
                                    </div>
                                </div>


                                <div class="mt-4">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center gap-2">
                                            <div><input type="radio" name="inch&price" class="form-check-input"
                                                    id="inch&price"> <span class="inch_text">24 * 32 inch</span></div>
                                        </div>
                                        <div>
                                            <div> <span class="price"><del>৳1,595</del></span> <span
                                                    class="selling_price">৳1,595</span></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center gap-2">
                                            <div>
                                                <input type="radio" name="inch&price"
                                                    class="form-check-input custom_inch_radio" id="inch&price">
                                                <span class="custom">Custom</span>
                                                <span><input type="text" name="" id="" class="custom_inch_value1"
                                                        placeholder="in"></span>
                                                <span>*</span>
                                                <span><input type="text" name="" id="" class="custom_inch_value2"
                                                        placeholder="in"></span>

                                            </div>
                                        </div>
                                        <div>
                                            <div> <span class="price"><del>৳1,595</del></span> <span
                                                    class="selling_price">৳1,595</span></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-4 custom_border_for_size">

                                </div>


                                <!-- chose frame -->

                                <div class="mt-4">
                                    <p class="choose_frame_text">Choose Frame</p>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <input type="button" href="#" value="Standart"
                                                class="frame_button shadow active_frame_button">
                                        </div>

                                        <div class="col-md-3">
                                            <input type="button" href="#" value="Metal" class="frame_button shadow">
                                        </div>


                                        <div class="col-md-3">
                                            <input type="button" href="#" value="Wood" class="frame_button shadow">
                                        </div>



                                    </div>
                                </div>





                            </div>
                        </div>

                        <!-- end size tab content -->



                    </div>

                </div>
            </div>
        </div>






        <div class="col-lg-6" style="display: none;">
            <div class="container" style="max-width: 550px; margin-top: 20%; margin-left: 0px; text-align: left;">
                <form @submit.prevent="handleSubmit">

                    <div class="nav nav-tabs" id="myTabs" role="tablist">
                        <button class="nav-link" :class="{ active: activeTab === 'text' }"
                            @click="setActiveTab('text')">
                            Text
                        </button>
                        <button class="nav-link" :class="{ active: activeTab === 'image' }"
                            @click="setActiveTab('image')">
                            Image
                        </button>
                        <button class="nav-link" :class="{ active: activeTab === 'color' }"
                            @click="setActiveTab('color')">
                            Color
                        </button>
                    </div>

                    <!-- Tab content -->
                    <div class="tab-content">
                        <!-- Text Tab -->
                        <div class="tab-pane" :class="{ active: activeTab === 'text' }" id="text-tab" role="tabpanel">
                            <div v-for="(inputId, index) in arryText" :key="index" class="form-group mb-3">
                                <label :for="inputId">Field {{ index + 1 }}:</label>
                                <input type="text" class="form-control" :id="inputId"
                                    :placeholder="'Enter value for ' + inputId" v-model="svg[inputId]" />
                            </div>
                        </div>

                        <!-- Image Tab -->
                        <div class="tab-pane" :class="{ active: activeTab === 'image' }" id="image-tab" role="tabpanel">
                            <div v-for="(imageId, index) in arryImage" :key="index" class="form-group mb-3">
                                <label :for="imageId">Image {{ index + 1 }}:</label>
                                <input type="file" class="form-control" :id="imageId" accept="image/*"
                                    @change="onFileChange(imageId, index, $event)" />
                                <span :id="'btn-'+imageId+index" @click="resetImage(imageId, index)"
                                    class="ImgRemoveBtn">Reset Image</span>

                            </div>
                        </div>

                        <!-- Color Tab -->
                        <div class="tab-pane" :class="{ active: activeTab === 'color' }" id="color-tab" role="tabpanel">
                            <div v-for="(pathId, index) in arryColor" :key="index" class="form-group mb-3">
                                <label :for="pathId">Color {{ index + 1 }}:</label>
                                <!-- Replace text input with color input -->
                                <input type="color" class="form-control" :id="pathId"
                                    @input="onColorChange(pathId, $event)" />

                            </div>
                        </div>
                    </div>







                    <!-- <button type="submit" class="btn btn-primary">Submit</button> -->
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import InlineSvg from "vue-inline-svg";

    export default {
        name: "RatingIcon",
        props: ['formData'],
        components: {
            InlineSvg,
        },
        data() {
            return {
                activeTab: 'text',
                svg: {

                },
                arryText: [
                    'tspan75',
                ],
                arryImage: [
                    'image287',
                    'image297',
                    'image307',

                ],
                arryColor: [
                    'path295',
                    'path285',
                    'path305',

                    'path283',
                    'path281',

                ],
                url: null,
            };
        },
        mounted() {
        // Access formData from route params
        console.log('Received formData from route params:', JSON.parse(this.$route.params.formData));
        },
        methods: {

            onFileChange(imageId, index, e) {

                let restoreImageBtn = document.getElementById('btn-' + imageId + index)
                restoreImageBtn.style.display = 'block'

                const file = e.target.files[0];
                this.url = URL.createObjectURL(file);

                let previewImage = document.getElementById(imageId);
                previewImage.setAttribute('xlink:href', this.url);

                let isDragging = false;

                // Mouse down event
                previewImage.addEventListener('mousedown', (downEvent) => {
                    isDragging = true;

                    // Get the initial mouse position
                    const initialX = downEvent.clientX;
                    const initialY = downEvent.clientY;

                    // Get the initial image position
                    const initialImageX = parseFloat(previewImage.getAttribute('x')) || 0;
                    const initialImageY = parseFloat(previewImage.getAttribute('y')) || 0;

                    // Mouse move event
                    const onMouseMove = (moveEvent) => {
                        if (isDragging) {
                            // Calculate the new image position based on mouse movements
                            const deltaX = moveEvent.clientX - initialX;
                            const deltaY = moveEvent.clientY - initialY;

                            const newImageX = initialImageX + deltaX;
                            const newImageY = initialImageY + deltaY;

                            // Set the new image position
                            previewImage.setAttribute('x', newImageX);
                            previewImage.setAttribute('y', newImageY);
                        }
                    };

                    // Mouse up event
                    const onMouseUp = () => {
                        isDragging = false;

                        // Remove the event listeners once dragging is done
                        document.removeEventListener('mousemove', onMouseMove);
                        document.removeEventListener('mouseup', onMouseUp);
                    };

                    // Add event listeners for mouse move and mouse up
                    document.addEventListener('mousemove', onMouseMove);
                    document.addEventListener('mouseup', onMouseUp);
                });
            },
            onColorChange(pathId, event) {
                // Get the selected color value from the event
                const selectedColor = event.target.value;

                // Update the corresponding path element with the selected color
                const pathElement = document.getElementById(pathId);
                if (pathElement) {
                    pathElement.style.fill = selectedColor;
                }
            },
            onSvgLoaded() {

                let svgComponent = document.getElementById('ratingIcon');
                svgComponent.style.width = '60%';
                svgComponent.style.margin = '60 0 60 0';
                svgComponent.style.border = '3px solid black';
            },
            handleSubmit() {
                event.preventDefault();
            },
            setActiveTab(tab) {
                this.activeTab = tab;
            },

            resetImage(imageId, index) {

                let restoreImageBtn = document.getElementById('btn-' + imageId + index)
                restoreImageBtn.style.display = 'none'
                this.url = null; // You can set the default image URL here if needed

                const fileInput = document.getElementById(imageId);
                if (fileInput) {
                    fileInput.value = null;
                }

                // Reset the preview image to the default image or remove it
                let previewImage = document.getElementById(imageId);
                if (previewImage) {
                    previewImage.setAttribute('xlink:href', ''); // Set to the default image URL if needed
                }
            },

        },
        watch: {
            svg: {
                handler(newValues) {
                    // Iterate over the properties of svg and update corresponding elements in the SVG
                    for (const [id, value] of Object.entries(newValues)) {
                        const svgElement = this.$refs.inlineSvgRef.$el.querySelector(`#${id}`);
                        if (svgElement) {
                            svgElement.innerHTML = value;
                        }
                    }
                },
                deep: true, // Enable deep watching to watch nested properties
            },
        },

    };
</script>

<style scoped>
    .ImgRemoveBtn {
        display: none;
        cursor: pointer;
        color: red;

    }
</style>