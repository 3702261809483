<!-- GoogleMapComponent.vue -->
<template>
  <div>
    <iframe
      width="600"
      height="300"
      :src="mapUrl"
      loading="lazy"
    ></iframe>
    
    <p>Image Bellow:</p>
    <img src="" alt="Google Map Location">
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      apiKey: 'AIzaSyAOVYRIgupAurZup5y1PRh8Ismb1A3lLao',
      // Replace the following coordinates with your desired location
      latitude: 37.7749,
      longitude: -122.4194,
      zoom: 15,
      mapImageSrc: '',
    };
  },
  mounted() {

  },
  computed: {
    mapUrl() {
      return `https://www.google.com/maps/embed/v1/view?key=${this.apiKey}&center=${this.latitude},${this.longitude}&zoom=${this.zoom}`;
    },
  },
  methods: {
    
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
